<template>
  <div>
    <img
      src="@/assets/images/common/simbolo-outline.svg"
      alt="simbolo"
      class="img-fluid symbol position-fixed d-none d-lg-block"
    />
    <section id="intro-acerca">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0">About</h1>
            <h1><strong>Us</strong></h1>
          </div>
          <div class="col-12 col-md-6">
            <h4>
              Our <span class="color-primary-entia">vision</span> is to generate
              social welfare and economic value through our works and projects.
            </h4>
          </div>
          <div class="col-md-6"></div>
          <div class="col-12 col-lg-6 mb-3">
            <h6>OUR MISSION</h6>
            <p class="mb-1 en">
              ENTIA is a Mexican-owned company specialized in the execution of
              infrastructure projects, mainly focused on transmission and
              distribution lines, railway systems, electricity, roads and
              general building projects. ENTIA formally started operations in early 2022 following the
              acquisition of the Mexican subsidiary company belonging to a former
              multinational now defunct. Therefore, we are born with the
              enthusiasm and drive of a young company, but with 30 years of
              experience of building infrastructures in Mexico. Our culture is
              based on a firm commitment to all our stakeholders, respect for
              environmental conservation and the development of society in
              general.
            </p>

          </div>
          <div class="col-lg-2"></div>
          <div class="col-12 col-lg-4 mt-4 mt-lg-0">
            <h5 class="ibm-plex-sans ps-4 mb-3">
              <strong class="fw-bold">ENTIA</strong> means
            </h5>
            <ul>
              <li>Excellence</li>
              <li>Efficiency</li>
              <li>Effectiveness</li>
              <li>Ethics</li>
              <li>Equity</li>
              <li>Ensured delivery</li>
              <li>Effort</li>
              <li>Enthusiasm</li>
              <li>Entrepreneurial spirit</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="cifras" class="bg-dark-50-entia card border-0 rounded-0">
      <div class="row g-0 align-items-center">
        <div
          class="col-12 col-md-4 text-center text-xl-start d-none d-md-block"
        >
          <img
            src="@/assets/images/about/cifras-experiencia.webp"
            class="img-fluid"
            alt="cifras-experiencia"
          />
        </div>
        <div class="col-12 col-md-8">
          <div class="card-body">
            <img
              src="@/assets/images/common/bullet.svg"
              class="img-fluid"
              alt="cifras-experiencia"
            />
            <h1 class="mb-4">Figures that translate <br />into success.</h1>
            <div class="row row-cols-2 row-cols-xl-4">
              <div class="col">
                <figure class="item">
                  <h2 class="m-0 count" number="30">0</h2>
                  <figcaption class="caption">
                    <small>years of experience</small>
                  </figcaption>
                </figure>
              </div>
              <div class="col">
                <figure class="item">
                  <h2 class="m-0 count" number="60">0</h2>
                  <figcaption class="caption">
                    <small>Projects completed</small>
                  </figcaption>
                </figure>
              </div>
              <div class="col">
                <figure class="item">
                  <h2 class="m-0 count" number="3100">0</h2>
                  <figcaption class="caption">
                    <small>Km of Transmission Lines</small>
                  </figcaption>
                </figure>
              </div>
              <div class="col">
                <figure class="item">
                  <h2 class="m-0 count" number="3">0</h2>
                  <figcaption class="caption">
                    <small>billion $USD in total investment</small>
                  </figcaption>
                </figure>
              </div>
            </div>
            <button
              class="btn-entia btn-blue-entia mt-5 p-0"
              @click="$router.push('/en/portfolio')"
            >
              PORTFOLIO
            </button>
          </div>
        </div>
      </div>
    </section>
    <Newsletter />
    <Footer msg="Now that you know something about us, tell us your idea." />
  </div>
</template>

<script>
import Footer from "@/en/components/common/Footer";
import Newsletter from "@/en/components/home/Newsletter";

export default {
  name: "AboutEN",
  components: {
    Footer,
    Newsletter,
  },
  mounted() {
    if (this.$route.name == "AboutEN") {
      window.addEventListener("scroll", () => {
        const element = document.getElementById("cifras") || undefined;

        if (element) {
          const position = element.getBoundingClientRect();
          const counters = document.querySelectorAll(".count");
          const speed = 3500;

          // position.top < window.innerHeight && position.bottom >= 0 <- "Element is partially visible in screen"
          // position.top >= 0 && position.bottom <= window.innerHeight <- "Element is fully visible in screen"

          if (
            position.top >= 0 &&
            position.bottom <= window.innerHeight &&
            element
          ) {
            counters.forEach((counter) => {
              const animate = () => {
                const value = +counter.getAttribute("number");
                const data = +counter.innerText;
                const time = value / speed;

                if (data < value) {
                  counter.innerText = `+${Math.ceil(data + time).toLocaleString(
                    "en-US"
                  )}`;
                  setTimeout(animate, 15);
                } else {
                  counter.innerText = `+${value.toLocaleString("en-US")}`;
                }
              };

              animate();
            });
          }
        }
      });
    }
  },
  destroyed() {
    window.removeEventListener("scroll", () => {});
  },
};
</script>

<style lang="scss" scoped>
#newsletter {
  position: relative;
  z-index: 2;
  ::-webkit-input-placeholder {
    text-align: center;
    color: #463d9c;
    font-weight: 300;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
    color: #463d9c;
    font-weight: 300;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
    color: #463d9c;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    text-align: center;
    color: #463d9c;
    font-weight: 300;
  }

  & button {
    font-weight: bold;
    letter-spacing: 1.8px;
    min-height: 40px;
    width: 138px;
    font-size: 24px;
  }
}

.footer {
  position: relative;
  z-index: 2;
}
</style>
