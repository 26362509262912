import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyA8hDueTBzS5M8o4gehW6dKa6J6gRiAEKU",
  authDomain: "bamf-85178.firebaseapp.com",
  databaseURL: "https://bamf-85178-default-rtdb.firebaseio.com",
  projectId: "bamf-85178",
  storageBucket: "bamf-85178.appspot.com",
  messagingSenderId: "692783854642",
  appId: "1:692783854642:web:febb32e9d25c00b5d0f82b"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;

Vue.prototype.$database = firebase.database();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
