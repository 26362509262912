<template>
  <div>
    <section id="intro" class="position-relative">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-lg-6">
            <h1 class="m-0">Work</h1>
            <h1><strong>with us</strong></h1>

            <p class="my-4">
              <span class="color-primary-entia fw-bold">ENTIA</span>
              is one of the leading infrastructure construction companies in
              Mexico. Our project trajectory is based on an experienced team of
              professionals and on the quality of our work, while always focused
              on the client.
            </p>
            <p>
              <a
                class="btn-entia btn-blue-entia"
                href="https://www.linkedin.com/company/entia-de-mexico/jobs/"
              >
                Vacancies
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="nuestra-forma">
      <div class="container">
        <div class="row pt-5">
          <div class="col-md-5 col-sm-12">
            <div class="row">
              <div class="arrow-container">
                <span class="float-start"></span>
                <button class="arrow-button float-start" data-state="">
                  <i></i>
                  <i></i>
                  <i></i>
                </button>
              </div>
            </div>
            <div class="row">
              <h1 class="">
                <strong
                  >About <br />
                  us</strong
                >
              </h1>

              <p>
                The development of our projects requires all the dedication and
                attention of our team and therefore, the talent of our
                professionals is a priority.
              </p>
              <p>
                In
                <span data-v-d7d2f0be="" class="color-primary-entia fw-bold"
                  >ENTIA</span
                >
                you will find opportunities to be a part of a team of
                professionals who will walk alongside you as you achieve your
                highest potential. Our success and accomplishments have been
                possible thanks to those who have taken us there with their
                diligent work and determination.
              </p>
              <p>
                <a
                  class="btn-entia btn-blue-entia"
                  href="https://www.linkedin.com/company/entia-de-mexico/jobs/"
                >
                  Vacancies
                </a>
              </p>
            </div>
          </div>
          <div class="col-md-7 col-sm-12 p-0">
            <img
              src="@/assets/images/work/vacantes.webp"
              alt="vacante"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>

    <section id="experiencia">
      <div class="container">
        <div class="row pt-5">
          <div class="col d-flex align-items-center justify-content-center">
            <p class="text-center">
              We want the best from each employee and therefore we encourage a
              <span class="color-primary-entia fw-bold"
                >work environment of excellence in a motivating and exciting
                space</span
              >
              that allows us to work in new ways which are more agile and
              efficient.

              <br /><br />

              <strong>
                We would be delighted to receive your application for available
                vacancies.
              </strong>
            </p>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col d-flex justify-content-center">
            <img src="@/assets/images/common/Trazado_6.png" alt="" width="60" />
          </div>
        </div>
        <div class="col d-flex justify-content-center mt-5">
          <a
            href="https://www.linkedin.com/company/entia-de-mexico/"
            target="_blank"
            class="lk-icon position-absolute btn-solid-entia"
          >
            <img
              src="@/assets/images/social/linkedin-entia.svg"
              alt=""
              class="img-fluid"
            />
            APPLY NOW
          </a>
        </div>
      </div>
    </section>

    <Footer msg="Got a project in mind?" />
  </div>
</template>

<script>
import Footer from "@/en/components/common/Footer";

export default {
  name: "Home",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.btn-solid-entia {
  background-color: #001e35;
  border: 1px solid #fff;
  padding: 10px 50px;
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
}
.btn-solid-entia:hover {
  background: #fd6900;
  border: 1px solid #001e35;
}
.btn-solid-entia img {
  width: 30px;
  margin-right: 20px;
}
#experiencia {
  background-color: #001e35;
  min-height: 600px;
  display: block;
  padding-bottom: 5rem;
}
#experiencia p {
  margin-top: 3rem;
  color: #fff;
  font-size: 1.2rem;
  max-width: 950px;
  font-size: 1.4rem;
  font-weight: lighter;
}
a.btn-entia {
  letter-spacing: 1.8px;
  font-size: 22px;
  font-family: "IBM Plex Sans", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 20px;
  text-decoration: none;
}
#intro {
  background-image: none;
}
.arrow-button i:nth-child(2),
.arrow-button {
  width: 80px;
}
.arrow-button i {
  height: 3px;
  min-width: 35px;
}
#nuestra-forma {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 10px;
}
#nuestra-forma h1 {
  font-size: 2.5rem;
  margin-top: 1rem;
  font-weight: normal;
  font-family: "IBM Plex Sans", sans-serif;
}
#nuestra-forma p {
  font-size: 1.2rem;
  font-family: "Space Grotesk", sans-serif;
  margin-top: 1.2rem;
  font-weight: lighter;
}
#nuestra-forma a {
  margin-bottom: 3rem;
}
#nuestra-forma img {
  padding-left: 2rem;
  padding-right: 0px;
}
</style>
