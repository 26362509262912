<template>
  <div class="bg-gray-50-entia">
    <section id="proyectos">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-12">
            <p class="mb-1 space-grotesk fw-light">
              Extensive and proven experience.
            </p>
            <h1 class="ibm-plex-sans fw-bold mb-4">
              Construction of infrastructure
            </h1>
            <img
              src="@/assets/images/common/bullet.svg"
              alt="bullet"
              class="img-fluid"
            />
          </div>
          <div class="col-12 col-lg-6 mt-3">
            <p class="space-grotesk fw-light">
              Our <span class="fw-bold">portfolio</span> is the result of our team's professionalism and 
              the great decisions made by our amazing clients. 
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-5 proyecto-transmision" >
            <a
            class="d-flex align-items-center proyectos"
            @click="scrollBehavior('portafolio-transmision')"
          >
            Electricity transmission and distribution lines
            <span class="ms-auto">01</span>
          </a>
          </div>
          <div class="col-12 mt-3 proyecto-electrica">
            <a
            class="d-flex align-items-center proyectos"
            @click="scrollBehavior('portafolio-ferroviarias')"
          >
              Railway infrastructure
              <span class="ms-auto">02</span>
          </a>
          </div>
          <div class="col-12 mt-3 proyecto-energia">
            <a
            class="d-flex align-items-center proyectos"
            @click="scrollBehavior('portafolio-energia')"
          >
              Power generation and environment
              <span class="ms-auto">03</span>
          </a>
          </div>
          <div class="col-12 mt-3 proyecto-carretera">
            <a
            class="d-flex align-items-center proyectos"
            @click="scrollBehavior('portafolio-infraestructura')"
          >
              Roads and building projects
              <span class="ms-auto">04</span>
          </a>
          </div>
        </div>
      </div>
    </section>
    <section id="portafolio-transmision" class="pb-5">
      <div class="container">
        <div class="row bg-dark-50-entia my-5">
          <div class="col-12 col-lg-7 p-0"></div>
        <div class="col-12 col-lg-5 p-5">
          <h1 class="bullet">Electicity transmission <br> and distribution lines</h1>
          <p><span class="color-white-entia fw-bold">Power transmission plays a key role in the current and future</span> needs of electrical
            infrastructure required by the growth of energy demand in our country. With more than 50 transmission line and substation projects 
            developed throughout the country, representing more than <span class="color-white-entia fw-bold">5,000 kilometers of lines,</span> the professionals at 
            <span class="color-primary-entia fw-bold">ENTIA</span> have the experience and skill necessary for a successful execution meeting the most demanding requirements of
            our clients.</p>
        </div>
      </div>
      </div>
      <div class="row justify-content-center">
          <div class="col-12 col-lg-9">
            <div class="col text-center">
              <img 
                src="@/assets/images/portfolio/entia-portafolio-transmision-distribucion-1-en.jpg"
                class="img-fluid"
              > 
            </div>
            <div class="row text-center">
              <p class="color-dark-50-entia">ENTIA is <span class="color-dark-50-entia fw-bold">a leader in the transmission and distribution sector,</span> 
                having <br>executed more than 50 projects throughout the country.</p>
            </div>
          </div>
      </div>
      <div class="container">

      <div class="row pe-3">
          <div class="row justify-content-end me-2">
          <div class="col-3 justify-content-end">
          <span class="arrow-container float-lg-end">
                <button
                  class="arrow-button arrow-btn"
                  data-state=""
                  @click="moveDivRight('hs-transmision')"
                >
                  <i></i>
                  <i></i>
                  <i></i>
                </button>
              </span>
          </div>
          </div>
      </div>
      <div class="row">
          <div class="row scroll-horizontal" id="hs-transmision">
            <div :class="{'col-lg-5 col-12 align-content-center m-1':item.image, 'col-lg-3 col-8 m-1 align-content-center': !item.image}" v-for="(item,index) in transmision" :key="item.titulo">
              <div class="row h-100">
                <div v-if="item.image" :class="`col-lg-5 col-7 transmision-${index+1}`"></div>
              <div :class="{'col-lg-7 col-5 py-4':item.image, 'col-lg-12 col-12 py-4':!item.image}">
                <p class="color-dark-50-entia  text-wrap "><span class="fw-bold color-secondary-entia">{{ item.titulo }}</span></p>
                <p class="color-dark-50-entia text-wrap"><span class="fw-bold color-dark-50-entia">Client: </span> {{ item.cliente }}</p>
                <p class="color-dark-50-entia text-wrap"><span class="fw-bold color-dark-50-entia">Budget: </span> {{ item.presupuesto }}</p>
                <p class="color-dark-50-entia text-wrap mb-0"><span class="fw-bold color-dark-50-entia">Year delivered: </span> {{ item.entrega }}</p>
              </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </section>
    <section id="portafolio-ferroviarias">
      <div class="container">
        <div class="row bg-dark-50-entia my-5">
          <div class="col-12 col-lg-5 p-5">
            <h1 class="bullet">Railway <br />infrastructure</h1>
            <p>
              We are convinced that <span>railway transportation is and will be a fundamental means</span> 
              to efficiently and sustainably meet the mobility needs of people and cargo in the short, medium and
              long term in the country.
            </p>
            <p>
              We offer <span>extensive experience</span> in the implementation and installation of track, catenary and electrical 
              systems in railway infrastructures.
            </p>
          </div>
          <div class="col-12 col-lg-7 p-0"></div>
        </div>
      </div>
    </section>
    <section id="portafolio-energia">
      <div class="container">
        <div class="row bg-dark-50-entia">
          <div class="col-12 col-lg-7 p-0"></div>
          <div class="col-12 col-lg-5 p-5">
            <h1 class="bullet">Energy and <br> enviromental sustainability</h1>
            <p>
              Energy generation and environmental conservation are two key aspects of a country's growth and sustainable 
              development. At <span>ENTIA,</span> we constantly strive to leverage our extensive experience in <span>combined 
              cycle and thermoelectric generation projects,</span> as well as in initiatives focused on reducing emissions and 
              developing renewable energy sources like photovoltaic plants and wind farms.
            </p>
          </div>
        </div>
      </div>
      <div class="container my-4">
        <div class="row pe-3">
      <div class="row d-flex justify-content-end">
        <div class="col-3 justify-content-end">
        <span class="arrow-container float-lg-end">
              <button
                class="arrow-button arrow-btn"
                data-state=""
                @click="moveDivRight('hs-energia')"
              >
                <i></i>
                <i></i>
                <i></i>
              </button>
            </span></div>
      </div>
        </div>
        <div class="row">
        <div class="row scroll-horizontal justify-content-evenly" id="hs-energia">
          <div class="col-lg-6 col-12 me-3 my-2" v-for="(item,index) in energia" :key="item.titulo">
            <div class="row justify-content-start h-100">
            <div :class="`col-6 energia-${index+1}`"
                    data-bs-toggle="modal"
                    data-bs-target="#lightBox"
                    @click.prevent="handleImage(index+1,true)"></div>
            <div class="col-6 py-4">
              <div class="col-12 my-2">
                <p class="color-dark-50-entia text-start text-wrap mb-0"><span class="fw-bold color-secondary-entia">{{ item.title }}</span></p>
              </div>
              <div class="col-12 text-start mt-3">
                <p class="color-dark-50-entia text-wrap mb-0"><span class="fw-bold color-dark-50-entia">Client: </span> {{ item.client }}</p>
                <p class="color-dark-50-entia text-wrap my-0"><span class="fw-bold color-dark-50-entia">Budget: </span> {{ item.budget }}</p>
                <p v-if="item.capacity" class="color-dark-50-entia text-wrap my-0"><span class="fw-bold color-dark-50-entia">Generating Capacity: </span>{{ item.capacity }}</p>
                <p class="color-dark-50-entia text-wrap"><span class="fw-bold color-dark-50-entia">Year delivered: </span> {{ item.year }}</p></div>
            </div>
          </div>
          </div>         
        </div>
        </div>
        </div>
    </section>
    <section id="portafolio-infraestructura">
      <div class="container">
        <div class="row bg-dark-50-entia mb-5">
          <div class="col-12 col-lg-5 p-5">
            <h1 class="bullet">
              Powers and<br />
              building projects
            </h1>
            <p>
              Highway systems are critical to achieving safe, <span>efficient connections</span> 
              among communities in Mexico. <span>We have developed and built some of the most advanced 
                highways in the country,</span>
                as well as state highways, with investments of more than $600 million dollars. 
                Our professionals have also participated in 
              <span
                >the execution of important international highway projects. </span
              >
              We also have vast experience in the construction of public facilities such as hospitals and local health care centers.
            </p>
          </div>
          <div class="col-12 col-lg-7 p-0"></div>
        </div>
      </div>
        <div class="container">
      <div class="row pe-3">
        <div class="row d-flex justify-content-end">
        <div class="col-3 justify-content-end">
        <span class="arrow-container float-lg-end">
              <button
                class="arrow-button arrow-btn"
                data-state=""
                @click="moveDivRight('hs-edificacion')"
              >
                <i></i>
                <i></i>
                <i></i>
              </button>
            </span></div>
      </div>
      </div>
      <div class="row">

        <div class="row scroll-horizontal justify-content-evenly" id="hs-edificacion">
            <div class="col-lg-6 col-12 m-1 my-2" v-for="(item,index) in infraestructura" :key="item.titulo">
            <div class="row justify-content-start h-100">
            <div :class="`col-6 infraestructura-${index+1}`"
                  data-bs-toggle="modal"
                  data-bs-target="#lightBox"
                  @click.prevent="handleImage(index + 1, false)"></div>
            <div class="col-6 py-4">
              <div class="col-12 my-2">
                <p class="color-dark-50-entia text-wrap"><span class="fw-bold color-secondary-entia">{{ item.title }}</span></p>
              </div>
              <div class="col-12 text-start mt-3">
                <p class="color-dark-50-entia text-wrap mb-0"><span class="fw-bold color-dark-50-entia">Client: </span> {{ item.client }}</p>
                <p class="color-dark-50-entia mb-0"><span class="fw-bold color-dark-50-entia text-wrap">Budget: </span> {{ item.budget }}</p>
                <p class="color-dark-50-entia mb-0"><span class="fw-bold color-dark-50-entia text-wrap">Year delivered: </span> {{ item.year }}</p>
              </div>
            </div>
            </div>
            </div>
            
          </div>
      </div>
        </div>
    </section>

    <section id="compania" class="bg-gray-50-entia text-center overflow-hidden">
      <div class="container position-relative">
        <img
          src="@/assets/images/common/simbolo-outline-orange.svg"
          alt="simbolo-outline"
          class="img-fluid"
        />
        <div
          class="
            row
            justify-content-center
            align-items-center
            position-absolute
          "
        >
          <div class="col-12 col-lg-8 col-xl-6">
            <p>
              <strong class="fw-bold">ENTIA</strong> is a company focused on the 
              <strong
                >engineering, construction, development, and management of public works infrastructure projects, </strong
              >
              elated to the transmission, distribution and generation of energy, as well as railway, road and building infrastructure.
            </p>
          </div>
        </div>
      </div>
    </section>
    
    <Footer msg="We have the experience you are looking for." />
    <!-- Modal -->
    <div
      class="modal fade"
      id="lightBox"
      tabindex="-1"
      aria-labelledby="lightBoxLabel"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog modal-dialog-centered modal-lg
          text-center
          justify-content-center
        "
      >
        <img :src="imgSelected" alt="light-box" class="img-fluid" />
      </div>
    </div>
  </div>
</template>


<script>
import Footer from "@/en/components/common/Footer";

export default {
  name: "PortfolioEN",
  components: {
    Footer,
  },
  data() {
    return {
      energia: [
        {
          title: "La Mesa and Ciudad Victoria Wind Farms.",
          client: "Compañía Eólica La Mesa - Cd. Victoria",
          budget: "$160M USD",
          year: 2017,
        },
        {
          title: "Sulfur recovery plant,Refineria Salamanca, Gto.",
          client: "PEMEX",
          budget: "$57M USD",
          year: 2016,
        },
        {
          title: 'Central Baja California',
          client: 'CFE',
          budget: '$174M USD',
          year: 2009
        },
        {
          title:
            "Rosarito Baja California, combined cycle power plant.",
          client: "Comisión Federal de Electricidad",
          budget: "$175M USD",
          capacity: "270 MW",
          year: 2009,
        },
        {
          title: 'Central de energía de Guerrero Negro',
          client: 'CFE',
          budget: '$27M USD',
          year: 2004
        },
        {
          title:
            "Rehabilitation and modernization of the Plutarco Elías Calles thermal power plant.",
          client: "Comisión Federal de Electricidad",
          budget: "$20M USD",
          capacity: "6x350 MW",
          year: 2009,
        },
      ],
      infraestructura: [
        {
          title: "Perote - Xalapa Highway",
          client: "SCT",
          budget: "$340M USD",
          year: 2012,
        },
        {
          title: "San José del Rincón rural hospital",
          client: "Instituto Mexicano del Seguro Social",
          budget: "$6M USD",
          year: 2011,
        },
        {
          title:
            "Puerto Vallarta​​ wastewater treatment plant",
          client: "SEAPAL",
          budget: "$8M USD",
          year: 2011,
        },
        {
          title: "Hospital de las Culturas San Cristóbal de las Casas",
          client: "Secretaría de la Infraestructura del Gobierno de Chiapas",
          budget: "$14M USD",
          year: 2010,
        },
        {
          title: "Saltillo-Monterrey Highway",
          client: "Concesionaria Autopista Monterrey-Saltillo",
          budget: "$280M USD",
          year: 2009,
        },
      ],
      finalizados: [
        {
          title: "Los Mochis Substation.",
          client: "CFE",
          budget: "MXN$ 36 millones y USD $ 12 Millones",
          year: 1995,
        },
        {
          title: "Southeast Substation",
          client: "CFE",
          budget: "USD$ 45 Millones",
          year: 2001,
        },
        {
          title: "Western subtransmission Line.",
          client: "CFE",
          budget: "USD$ 27 Millones",
          year: 2006,
        },
        {
          title: "Queretaro and Celaya substation.",
          client: "CFE",
          budget: "MXN$ 12 millones y USD$ 97 Millones",
          year: 1995,
        },
        {
          title: "Linea Altamira ll, lll and IV.",
          client: "CFE",
          budget: "USD$ 247 Millones",
          year: 2002,
        },
        {
          title: "Northwest-North Transmission Line.",
          client: "CFE",
          budget: "USD$ 120 Millones",
          year: 2004,
        },
        {
          title: "Carbón II - Lampazos Transmission Line.",
          client: "CFE",
          budget: "MXN$ 20 millones y USD$ 7 millones",
          year: 1995,
        },
        {
          title: "North-Western Transmission Line.",
          client: "CFE",
          budget: "MXN$ 65 millones",
          year: 2002,
        },
        {
          title: "Yautepec line.",
          client: "CFE",
          budget: "MXN$ 72 millones y USD$ 11 millones",
          year: 2005,
        },
        {
          title: "Southeast transmission line.",
          client: "CFE",
          budget: "USD$ 42 millones",
          year: 2001,
        },
        {
          title: "Guerrero Negro power plant.",
          client: "CFE",
          budget: "USD$ 27 millones",
          year: 2004,
        },
        {
          title: "Southeast Peninsular subtransmission lines.",
          client: "CFE",
          budget: "USD$ 9 millones",
          year: 2006,
        },
        {
          title: "Northwest-North transmission and transformation lines",
          client: "CFE",
          budget: "USD$ 16 millones",
          year: 2012,
        },
        {
          title: "Tula, Refinery transmission line.",
          client: "PEMEX",
          budget: "USD$ 160 millones",
          year: 2013,
        },
        {
          title: "Buenavista del Cobre transmission line.",
          client: "MINERA MÉXICO",
          budget: "USD$ 6 millones",
          year: 2014,
        },
        {
          title: "Gulf North Transmission Lines (Phase 2)",
          client: "CFE",
          budget: "USD$ 8 millones",
          year: 2015,
        },
        {
          title: "Buenavista substation",
          client: "MINERA MÉXICO",
          budget: "$7M USD",
          year: 2014,
        },
        {
          title: "​​Oaxaca Transmission Lines II, III, IV",
          client: "CFE",
          budget: "$209M USD",
          year: 2010,
        },
        {
          title: "Azul de Cortés line.",
          client: "AZUL DE CORTÉS",
          budget: "$6M USD",
          year: 2011,
        },
        {
          title: "Northeast transmission and transformation line (Phase 3).",
          client: "CFE",
          budget: "$44M USD",
          year: 2013,
        },
        {
          title: "ITSMO Transmission line",
          client: "CFE",
          budget: "$63M USD",
          year: 2007,
        },
        {
          title: "Baja California plant.",
          client: "CFE",
          budget: "$174M USD",
          year: 2009,
        },
        {
          title: "Northeast distribution substation",
          client: "CFE",
          budget: "$4M USD",
          year: 2013,
        },
        {
          title: "Guatemala-Mexico Transmission line",
          client: "INDE GUATEMALA",
          budget: "$16M USD",
          year: 2009,
        },
        {
          title: "Northeast transmission and transformation line (Phase 2).",
          client: "CFE",
          budget: "$16M USD",
          year: 2010,
        },
      ],
      transmision:[
        {
          titulo: 'Substation Cuchillo Banco 2, Nuevo León',
          cliente: 'CFE',
          presupuesto: 'MXN$ 131.3 Millones',
          entrega: '2023',
          image: require(`@/assets/images/portfolio/carruselTransmision/transmision-1.jpg`)
        },
        {
          titulo: 'Gulf North Transmission Lines (Phase 2)',
          cliente: 'CFE',
          presupuesto: '$8M USD',
          entrega: '2015'
        },
        {
          titulo: 'Buenavista substation',
          cliente: 'MINERA MÉXICO',
          presupuesto: '$7M USD',
          entrega: '2014',
          image: require(`@/assets/images/portfolio/carruselTransmision/transmision-3.jpg`)
        },
        {
          titulo: 'Buenavista del Cobre transmission line',
          cliente: 'MINERA MÉXICO',
          presupuesto: '$6M USD',
          entrega: '2014'
        },
        {
          titulo: 'Northeast distribution substation',
          cliente: 'CFE',
          presupuesto: '$4M USD',
          entrega: '2013'
        },
        {
          titulo: 'Northeast transmission and transformation line (Phase 3)',
          cliente: 'CFE',
          presupuesto: '$44M USD',
          entrega: '2013'
        },
        {
          titulo: 'Tula, Refinery transmission line',
          cliente: 'PEMEX',
          presupuesto: '$160M USD',
          entrega: '2013'
        },
        {
          titulo: 'Northwest-North transmission and transformation lines',
          cliente: 'CFE',
          presupuesto: '$18M USD',
          entrega: '2012'
        },
        {
          titulo: 'Azul de Cortés line',
          cliente: 'AZUL DE CORTÉS',
          presupuesto: '$6M USD',
          entrega: '2011'
        },
        {
          titulo: '​​Oaxaca Transmission Lines II, III, IV',
          cliente: 'CFE',
          presupuesto: '$209M USD',
          entrega: '2010'
        },
        {
          titulo: 'Northeast transmission and transformation line (Phase 2).',
          cliente: 'CFE',
          presupuesto: '$16M USD',
          entrega: '2010'
        },
        {
          titulo: 'Guatemala-Mexico Transmission line',
          cliente: 'INDE GUATEMALA',
          presupuesto: '$16M USD',
          entrega: '2009'
        },
        {
          titulo: 'Baja California plant',
          cliente: 'CFE',
          presupuesto: '$174M USD',
          entrega: '2009'
        },
        {
          titulo: 'ITSMO Transmission line',
          cliente: 'CFE',
          presupuesto: '$63M USD',
          entrega: '2007'
        },
        {
          titulo: 'Southeast Peninsular subtransmission lines',
          cliente: 'CFE',
          presupuesto: '$9M USD',
          entrega: '2006',
          image: require(`@/assets/images/portfolio/carruselTransmision/transmision-15.jpg`)
        },
        {
          titulo: 'Western subtransmission Line',
          cliente: 'CFE',
          presupuesto: '$27M USD',
          entrega: '2006'
        },
        {
          titulo: 'Yautepec line',
          cliente: 'CFE',
          presupuesto: 'MXN$ 72 millones y USD $ 11 millones',
          entrega: '2005',
          image:  require(`@/assets/images/portfolio/carruselTransmision/transmision-17.jpg`)
        },
        {
          titulo: 'Guerrero Negro power plant',
          cliente: 'CFE',
          presupuesto: '$27M USD',
          entrega: '2004'
        },
        {
          titulo: 'Northwest-North Transmission Line',
          cliente: 'CFE',
          presupuesto: '$120M USD',
          entrega: '2004'
        },
        {
          titulo: 'Línea Altamira II, III and IV',
          cliente: 'CFE',
          presupuesto: '$247M USD',
          entrega: '2002'
        },
        {
          titulo: 'North-Western Transmission Line',
          cliente: 'CFE',
          presupuesto: 'MXN$ 65 millones',
          entrega: '2002'
        },
        {
          titulo: 'Southeast transmission line',
          cliente: 'CFE',
          presupuesto: '$42M USD',
          entrega: '2001'
        },
        {
          titulo: 'Southeast Substation',
          cliente: 'CFE',
          presupuesto: '$45M USD',
          entrega: '2001'
        },
        {
          titulo: 'Los Mochis Substation',
          cliente: 'CFE',
          presupuesto: 'MXN$ 36 millones y USD $12 millones',
          entrega: '1995'
        },
        {
          titulo: 'Queretaro and Celaya substation',
          cliente: 'CFE',
          presupuesto: 'MXN$ 12 millones y USD$ 97 millones',
          entrega: '1995'
        },
        {
          titulo: 'Carbón II - Lampazos Transmission Line',
          cliente: 'CFE',
          presupuesto: 'MXN$ 20 millones y USD$ 7 millones',
          entrega: '1995'
        }
      ],
      imgSelected: "",
      visible: 3,
    };
  },
  created() {
    setTimeout(() => {
      if (this.$route.hash !== '') {
        const hash = window.location.hash.split("#")[1];
        this.scrollBehavior(hash);
      }
    }, 1000);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    toBeShown() {
      return this.finalizados.slice(0, this.visible * 3);
    },
    totalItems() {
      return Math.ceil(this.finalizados.length / 3);
    },
  },
  methods: {
    scrollBehavior(id) {
      let element = document.getElementById(id);
      const offset = 145; 
      const elementPosition = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    },
    handleImage(index, flag) {
      if (flag) {
        this.imgSelected = require(`@/assets/images/portfolio/carruselEnergia/entia-portafolio-energia-carrusel-${index}.webp`);
      } else {
        this.imgSelected = require(`@/assets/images/portfolio/carruselInfraestructura/entia-portafolio-infraestructura-carrusel-${index}.webp`);
      }
    },
    nextItems() {
      if (this.visible < this.totalItems) this.visible += 3;
    },
    moveDivRight(id) {
      let next = 0;
      let step = 10;
      let speed = 10;
      let distance = 450;
      let scrollAmount = 0;
      let element = document.getElementById(id);

      let slideTimer = setInterval(function () {
        next = element.scrollLeft + step;
        element.scrollLeft = next;
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    },
  },
};
</script>

<style></style>
