import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../es/views/Home";
import HomeEN from "../en/views/Home";
import About from "../es/views/About";
import AboutEN from "../en/views/About";
import Services from "../es/views/Services";
import ServicesEN from "../en/views/Services";
import Portfolio from "../es/views/Portfolio";
import PortfolioEN from "../en/views/Portfolio";
import Ferroviaria from "../es/views/portfolio/Ferroviaria";
import FerroviariaEN from "../en/views/portfolio/Ferroviaria";
import Electrica from "../es/views/portfolio/Electrica";
import ElectricaEN from "../en/views/portfolio/Electrica";
import Carretera from "../es/views/portfolio/Carretera";
import CarreteraEN from "../en/views/portfolio/Carretera";
import Energia from "../es/views/portfolio/Energia";
import EnergiaEN from "../en/views/portfolio/Energia";
import Ejecucion from "../es/views/InProgress"
import InProgress from "../en/views/InProgressEN";
import Contact from "../es/views/Contact";
import ContactEN from "../en/views/Contact";
import TrabajaConNosotros from "../es/views/TrabajaConNosotros";
import WorkForUs from "../en/views/WorkForUs";
import Privacy from "../es/views/Privacy";
import PrivacyEN from "../en/views/Privacy";
import NewsletterDB from "../es/views/NewsletterDB";
// import { component } from "vue/types/umd";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/es",
  },
  {
    path: "/es",
    name: "Home",
    component: Home,
  },
  {
    path: "/en",
    name: "HomeEN",
    component: HomeEN,
  },
  {
    path: "/es/acerca",
    name: "About",
    component: About,
  },
  {
    path: "/en/about",
    name: "AboutEN",
    component: AboutEN,
  },
  {
    path: "/es/servicios",
    name: "Services",
    component: Services,
  },
  {
    path: "/en/services",
    name: "ServicesEN",
    component: ServicesEN,
  },
  {
    path: "/es/portafolio",
    name: "Portfolio",
    component: Portfolio,
  },
  {
    path: "/en/portfolio",
    name: "PortfolioEN",
    component: PortfolioEN,
  },
  {
    path: "/es/portafolio/infraestructuras-ferroviarias",
    name: "InfraestructurasFerroviarias",
    component: Ferroviaria,
  },
  {
    path: "/en/portfolio/railway-infrastructure",
    name: "InfraestructurasFerroviariasEN",
    component: FerroviariaEN,
  },
  {
    path: "/es/portafolio/lineas-de-transmision-y-distribucion-electrica",
    name: "LineasDeTransmisionYDistribucionElectrica",
    component: Electrica,
  },
  {
    path: "/en/portfolio/electricity-transmission-and-electrical-line-distribution",
    name: "LineasDeTransmisionYDistribucionElectricaEN",
    component: ElectricaEN,
  },
  {
    path: "/es/portafolio/carreteras-y-edificacion",
    name: "CarreterasYEdificacion",
    component: Carretera,
  },
  {
    path: "/en/portfolio/roads-and-building-projects",
    name: "CarreterasYEdificacionEN",
    component: CarreteraEN,
  },
  {
    path: "/es/portafolio/generacion-de-energia-y-medio-ambiente",
    name: "generacionDeEnergiaMedioAmbiente",
    component: Energia,
  },
  {
    path: "/en/portfolio/power-generation-and-the-environment",
    name: "generacionDeEnergiaMedioAmbienteEN",
    component: EnergiaEN,
  },
  {
    path: "/es/proyectos-en-progreso",
    name: "Ejecucion",
    component: Ejecucion,
  },
  {
    path: "/en/InProgress",
    name: "InProgress",
    component: InProgress,
  },
  {
    path: "/es/contacto",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/es/trabaja-con-nosotros",
    name: "TrabajaConNosotros",
    component: TrabajaConNosotros,
  },
  {
    path: "/en/work-for-us",
    name: "WorkForUs",
    component: WorkForUs,
  },
  {
    path: "/en/contact-us",
    name: "ContactEN",
    component: ContactEN,
  },
  {
    path: "/es/aviso-de-privacidad",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/en/privacy-notice",
    name: "PrivacyEN",
    component: PrivacyEN,
  },
  {
    path: "/newsletter-db-entia",
    name: "NewsletterDB",
    component: NewsletterDB,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to) {
    if (!to.hash) {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

export default router;
