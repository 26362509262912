<template>
  <footer class="footer bg-gray-entia">
    <div class="container footer-top">
      <div class="row padding-footer-row">
        <div class="col-12 col-lg-6 align-items-lg-center d-flex">
          <img
            src="@/assets/images/common/entia-logo.svg"
            alt="entia-logo"
            class="mb-4 mb-lg-0"
            width="140"
          />
        </div>
        <div class="col-12 col-lg-6 text-end d-none d-lg-block">
          <img
            src="@/assets/images/common/entia-pattern-footer.svg"
            alt="entia-pattern-footer"
            width="398"
          />
        </div>
        <div class="col-12">
          <p>{{ msg }}</p>
          <a href="/es/contacto" class="d-flex align-items-center">
            <h1>INICIAR AHORA</h1>
            <span class="arrow-container">
              <button class="arrow-button" data-state="">
                <i></i>
                <i></i>
                <i></i>
              </button>
            </span>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ul class="list-group list-group-horizontal-lg">
            <li class="list-group-item col px-0">
              <router-link to="/es">Inicio</router-link>
            </li>
            <li class="list-group-item col px-0">
              <router-link to="/es/acerca">Acerca</router-link>
            </li>
            <li class="list-group-item col px-0">
              <router-link to="/es/servicios">Servicios</router-link>
            </li>
            <li class="list-group-item col px-0">
              <router-link to="/es/portafolio">Portafolio</router-link>
            </li>
            <li class="list-group-item col px-0">
              <a href="/es/contacto">Contacto</a>
            </li>
            <li class="list-group-item col px-0 em7">
              <a href="/es/trabaja-con-nosotros">Trabaja con nosotros</a>
            </li>
            <li class="list-group-item"></li>
            <li class="list-group-item col px-0">
              <router-link to="/es/aviso-de-privacidad"
                >Aviso de privacidad</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-dark-50-entia text-center">
      <p class="copyright py-3">&copy; {{ currentYear }} ENTIA</p>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.em7 a {
  color: #fd6900;
  font-weight: 600;
  font-family: "IBM Plex Sans";
  font-size: 1.1rem;
  line-height: 2rem;
}
.em7 {
  border-bottom: 4px solid #fd6900;
  padding-bottom: 7px;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 38px;
}
</style>
