<template>
  <div>
    <section id="intro" class="position-relative">
      <div class="container">
        <div class="row vh-100 align-items-center">
          <div class="col-12 col-lg-6">
            <h1 class="m-0">La experiencia</h1>
            <h1>construye el <strong>futuro</strong></h1>

            <p class="my-4">
              <span class="color-primary-entia fw-bold">ENTIA</span> es una
              empresa mexicana de construcción e ingeniería especializada en
              infraestructuras ferroviarias, sistemas eléctricos, líneas de
              transmisión y subestaciones, así como en carreteras y edificación.
            </p>

            <button
              class="btn-entia btn-blue-entia"
              @click="$router.push('/es/contacto')"
            >
              Contacto
            </button>
          </div>
          <div class="col-12 d-lg-none text-center">
            <img
              src="@/assets/images/home/intro-bg.webp"
              alt="intro-bg"
              class="img-fluid mt-3"
              width="223"
            />
          </div>
        </div>
        <a
          href="https://www.linkedin.com/company/"
          target="_blank"
          class="lk-icon position-absolute"
        >
          <img
            src="@/assets/images/social/linkedin-entia.svg"
            alt=""
            class="img-fluid"
          />
        </a>
        <a
          href="https://www.facebook.com/"
          target="_blank"
          class="fb-icon position-absolute"
        >
          <img
            src="@/assets/images/social/facebook-entia.svg"
            alt="fb-icon"
            class="img-fluid"
          />
        </a>
      </div>
    </section>
    <section id="servicios-inicio">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0">Nuestros</h1>
            <h1 class="pe-3"><strong>servicios</strong></h1>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col-12 col-lg-6 service-2 mb-3">
            <img
              src="@/assets/images/home/lineas-de-transmision-electrica.webp"
              alt="infraestructura-ferroviara"
              class="img-fluid"
            />
            <div class="row">
              <div class="col-12 my-3">
                <router-link
                  :to="{ path: 'es/portafolio', hash: '#portafolio-transmision' }"
                  class="text-decoration-none"
                >
                  <Arrow
                    service="Líneas de transmisión <br/> y distribución eléctrica"
                  />
                </router-link>
              </div>
              <div class="col-12">
                <p>
                  Las importantes capacidades en esta actividad se ponen de
                  manifiesto con la ejecución de más de 5,000 km de líneas de
                  transmisión realizados a lo largo de los últimos 25 años.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 service-1 mb-3">
            <img
              src="@/assets/images/home/infraestructura-ferroviaria.webp"
              alt="infraestructura-ferroviara"
              class="img-fluid"
            />
            <div class="row">
              <div class="col-12 my-3">
                <router-link
                  :to="{ path: 'es/portafolio', hash: '#portafolio-ferroviarias' }"
                  class="text-decoration-none"
                >
                  <Arrow service="Infraestructuras <br/> ferroviarias" />
                </router-link>
              </div>
              <div class="col-12">
                <p>
                  Ofrecemos amplia experiencia en la implementación e
                  instalación de vías, catenarias y sistemas eléctricos en
                  infraestructuras ferroviarias.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 service-4 mt-3">
            <img
              src="@/assets/images/home/generacion-energia.webp"
              alt="generacion-energia"
              class="img-fluid"
            />
            <div class="row">
              <div class="col-12 my-3">
                <router-link
                  :to="{ path: 'es/portafolio', hash: '#portafolio-energia' }"
                  class="text-decoration-none"
                >
                  <Arrow
                    service="Generación de energía <br/> y medio ambiente"
                  />
                </router-link>
              </div>
              <div class="col-12">
                <p>
                  La generación de energía unida al medio ambiente siempre ha
                  sido parte de nuestra identidad y compromiso con la sociedad.
                  Para ello contamos con experiencia en proyectos de generación
                  tanto termoeléctrica y ciclo combinado, como renovables en
                  plantas fotovoltaicas y parques eólicos.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 service-3 mt-3">
            <img
              src="@/assets/images/home/carreteras-edificacion.webp"
              alt="infraestructura-ferroviara"
              class="img-fluid"
            />
            <div class="row">
              <div class="col-12 my-3">
                <router-link
                  :to="{ path: 'es/portafolio', hash: '#portafolio-infraestructura' }"
                  class="text-decoration-none"
                >
                  <Arrow service="Carreteras <br/>y edificación" />
                </router-link>
              </div>
              <div class="col-12">
                <p>
                  Hemos desarrollado competencias relevantes en la construcción
                  de este tipo de infraestructuras mediante la ejecución de
                  proyectos en autopistas, carreteras, así como de equipamientos
                  públicos en distintos estados de la República.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="portafolio">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0">
              <strong>Nuestro <br />portafolio</strong>
            </h1>
          </div>
          <div class="col-12 mt-5">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: 'es/portafolio', hash: '#portafolio-transmision' }"
            >
              Transmisión y Distribución
              <span class="ms-auto">01</span>
            </router-link>
          </div>
          <div class="col-12 mt-3">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: 'es/portafolio', hash: '#portafolio-ferroviarias' }"
            >
              Infraestructuras Ferroviarias
              <span class="ms-auto">02</span>
            </router-link>
          </div>
          <div class="col-12 mt-3">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: 'es/portafolio', hash: '#portafolio-energia' }"
            >
              Generación de Energía y Medio Ambiente
              <span class="ms-auto">03</span>
            </router-link>
          </div>
          <div class="col-12 mt-3">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: 'es/portafolio', hash: '#portafolio-infraestructura' }"
            >
              Infraestructura y Edificación
              <span class="ms-auto">04</span>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <Newsletter />
    <Footer msg="¿Tienes un proyecto?" />
  </div>
</template>

<script>
import Footer from "@/es/components/common/Footer";
import Newsletter from "@/es/components/home/Newsletter";
import Arrow from "@/es/components/common/Arrow";

export default {
  name: "Home",
  components: {
    Footer,
    Newsletter,
    Arrow,
  },
};
</script>

<style lang="scss" scoped>
#newsletter {
  ::-webkit-input-placeholder {
    text-align: center;
    color: #ededed;
    font-weight: 300;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
    color: #ededed;
    font-weight: 300;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
    color: #ededed;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    text-align: center;
    color: #ededed;
    font-weight: 300;
  }
}
</style>
