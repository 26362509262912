<template>
  <div class="arrow-container">
    <p class="m-0" v-html="service"></p>
    <button class="arrow-button" data-state="">
      <i></i>
      <i></i>
      <i></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    service: String,
  },
};
</script>

<style>
</style>