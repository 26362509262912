var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"bg-gray-50-entia",attrs:{"id":"ferroviaria-carrusel"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row p-bottom"},[_c('div',{staticClass:"col-12"},[_c('router-link',{staticClass:"link-back",attrs:{"to":"/en/portfolio"}},[_vm._v("< Portfolio")])],1)])]),_c('div',{staticClass:"row g-0"},[_c('div',{staticClass:"col-12 position-relative"},[_c('div',{attrs:{"id":"portfolio-slider"}},[_c('div',{attrs:{"id":"portfolio-slides"}},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"slide"},[_c('img',{attrs:{"src":_vm.getImgUrl(item.img),"width":"400","height":"350"}})])}),0),_c('div',{attrs:{"id":"left-arrow"}},[_c('a',{staticClass:"btn",on:{"click":_vm.scrollLeft}},[_c('img',{attrs:{"src":require("@/assets/images/common/Arrow-L.svg"),"alt":""}})])]),_c('div',{attrs:{"id":"center-line"}}),_c('div',{attrs:{"id":"right-arrow"},on:{"click":_vm.scrollRight}},[_vm._m(0)])])]),_c('div',{staticClass:"row g-0"},[_vm._m(1),_c('div',{staticClass:"d-md-flex text-center justify-content-md-between navbuttons"},[_c('div',[_c('button',{staticClass:"mb-3 mb-md-0",on:{"click":function($event){return _vm.$router.push(
                  '/en/portfolio/electricity-transmission-and-electrical-line-distribution'
                )}}},[_vm._v(" Previous category ")])]),_c('div',[_c('button',{on:{"click":function($event){return _vm.$router.push(
                  '/en/portfolio/power-generation-and-the-environment'
                )}}},[_vm._v(" Next category ")])])])])])]),_c('Footer',{attrs:{"msg":"We have the experience you are looking for."}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn"},[_c('img',{attrs:{"src":require("@/assets/images/common/Arrow-R.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-grid justify-content-center"},[_c('h5',{staticClass:"text-center"},[_vm._v("02")]),_c('h4',{staticClass:"text-center"},[_vm._v("Railway infrastructure")])])
}]

export { render, staticRenderFns }