<template>
    <div>
        <section id="proyectos-progreso">
            <div class="container">
              <!-- presentacion -->
              <div class="row vh-100">
                <div class="col-12 mb-3 justify-content-center text-center">
                <p class="mb-1 space-grotesk fw-light my-4">Presentamos nuestros</p>
                <h1 class="ibm-plex-sans fw-bold my-4">Proyectos en Ejecución</h1>
              <img
                src="@/assets/images/common/bullet.svg"
                alt="bullet"
                class="img-fluid my-3"
                />
              </div>
                  <!-- lista proyectos -->
              <div class="col-12">
              <div class="row scroll-horizontal justidy-content-evenly">
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-uno shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('tren-interurbano')" class="cursor">
                  <ArrowService service="Tren Interurbano México - Toluca" />
                </a>
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-dos shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('suministro')" class="cursor">
                  <ArrowService service="Suministro de Cable del Tren Maya" />
                </a>
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-tres shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('Tramo-5-sur')" class="cursor">
                  <ArrowService service="Catenaria del tren Maya" />
                </a>
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-cuatro shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('Lecheria-AIFA')" class="cursor">
                  <ArrowService service="Sistema de Energía y SETE" />
                </a>  
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-cinco shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('Tramo-5-Norte')" class="cursor">
                  <ArrowService service="Instalación de la Catenaria tramo 5" />
                </a>  
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-seis shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('Tramos-5N-6')" class="cursor">
                  <ArrowService service="Telemando de Seccionadores de Catenaria" />
                </a>  
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-siete shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('slt')" class="cursor text-wrap">
                  <ArrowService service="Solución de Congestión de Enlaces" />
                </a>  
                </div>
                <div class="col-lg-4 col-md-6 col-11">
                  <div class="col-12 progreso-ocho shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('Tramo-6')" class="cursor">
                  <ArrowService service="Catenaria del tren Maya Tramo 6" />
                </a>  
                </div>
              </div>
              </div>
              </div>
            </div>
        </section>
        <section id="tren-interurbano">
          <div class="container">
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Tren Interurbano México - Toluca</h1>
            </div>
            </div>
            <div class="row h-100">
              <!-- galeria -->
               <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <!-- imagen temporal -->
                 <div id="carouselinterurbano"class="carousel slide carousel-fade " data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src="@/assets/images/inprogress/interurbano-mex-tol/01.webp" class="d-block w-100">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/inprogress/interurbano-mex-tol/02.webp" class="d-block w-100">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/inprogress/interurbano-mex-tol/03.webp" class="d-block w-100">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/inprogress/interurbano-mex-tol/04.webp" class="d-block w-100">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/inprogress/interurbano-mex-tol/05.webp" class="d-block w-100">
                    </div>
                  </div>
                  <div class="carousel-indicators">
                    <button
                    type="button"
                    data-bs-target="#carouselinterurbano"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselinterurbano"
                    data-bs-slide-to="1"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselinterurbano"
                    data-bs-slide-to="2"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselinterurbano"
                    data-bs-slide-to="3"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselinterurbano"
                    data-bs-slide-to="4"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  </div>
                 </div>
               </div>
               <div class="col descripcion">
                 <div class="col m-5">
                  <h3>Descripción del Proyecto</h3>
                  <p>El objeto del contrato es la ejecución de parte de la superestructura que incluye la vía,
                     catenaria y el sistema eléctrico de la totalidad del proyecto:
                  </p>
                  <ul>
                    <li>Ejecución de Vía, incluyendo los distintos aparatos de vía, encarriladoras, aparatos de dilatación.</li>
                    <li>Ejecución de Catenaria y suministro energético de equipos en línea y telemando de energía.</li>
                    <li>Ejecución de Subsestaciones de Tracción (2) 40MVA, acometidas en alta de 230kV y media tensión 23kV.</li>
                    <li>Ejecución de Subestaciones de Alumbrado y fuerza 23 kV, para estaciones de viajeros (6), talleres y cocheras (1+2), Edificios técnicos (2) y Galerías en túnel (5).</li>
                  </ul>
                  <h3>El Proyecto en cifras</h3>
                  <div class="row align-content-center text-center">
                    <div class="col-lg-5 col-6 mt-3">
                      <h1 class="m-0 fw-semibold">6,900</h1>
                      <h3 class="fw-semibold">MDP [MXN]</h3>
                    </div>
                    <div class="col-lg-5 col-6 align-content-center mt-3">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_SCT.png"
                        class="img-fluid">
                    </div>
                  </div>
                 </div>
               </div>
               <!-- informacion -->
            </div>
          </div>
        </section>
        <section id="suministro">
          <div class="container">
            <div class="row">
            <div class="d-flex text-start bg-dark-50-entia">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Suministro de Cable del Tren Maya</h1>
            </div>
            </div>
            <div class="row">
              <!-- galeria -->
               <div class=" my-4 col-12 col-lg-6 align-content-center justify-content-center">
                <!-- imagen temporal -->
                  <div id="carouselsuministro" class="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img src="@/assets/images/inprogress/suministro-tren-maya/01.webp" class="d-block w-100">
                      </div>
                      <div class="carousel-item">
                        <img src="@/assets/images/inprogress/suministro-tren-maya/02.webp" class="d-block w-100">
                      </div>
                      <div class="carousel-item">
                        <img src="@/assets/images/inprogress/suministro-tren-maya/03.webp" class="d-block w-100">
                      </div>
                      <div class="carousel-item">
                        <img src="@/assets/images/inprogress/suministro-tren-maya/04.webp" class="d-block w-100">
                      </div>
                      <div class="carousel-item">
                        <img src="@/assets/images/inprogress/suministro-tren-maya/05.webp" class="d-block w-100">
                      </div>
                    </div>
                  <div class="carousel-indicators">
                    <button
                    type="button"
                    data-bs-target="#carouselsuministro"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselsuministro"
                    data-bs-slide-to="1"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselsuministro"
                    data-bs-slide-to="2"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselsuministro"
                    data-bs-slide-to="3"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselsuministro"
                    data-bs-slide-to="4"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  </div>
                  </div>
               </div>
               <div class="col-12 col-lg-6 p-0 descripcion">
                 <div class="col m-5">
                  <h3>Descripción del Proyecto</h3>
                  <p>EL objeto del contrato es el suministro de diversos cables y aisladores en:
                  </p>
                  <ul>
                    <li>Construcción del Tren Maya, Tramo 5 Norte, de Cancún a Playa del Carmen Norte, Quintana Roo, Energización y Obra Electromecánica (Frente 4).</li>
                    <li>Construcción del Tren Maya, Tramo 6, Tulúm-Chetumal, Quintana Roo, Tramo 7, de Bacalar, Quintana Roo a Escárcega, Campeche. Energización y Obra Electromecánica (Frente 8).</li>
                    <li>Suministro de aisladores: seccionadores en carga unipolar, seccionadores en carga bipolares.</li>
                    <li>Suministro de cable: cable para seccionadores.</li>
                  </ul>
                  <h3>El Proyecto en cifras</h3>
                  <div class="row align-content text-center justify-content-evenly">
                    <div class="col-4 col-lg-6 my-3 ">
                      <h1 class="m-0 fw-semibold">147.6</h1>
                      <h3 class="fw-semibold">MDP [MXN]</h3>
                    </div>
                    <div class="col-lg-5 col-7 mb-3 align-content-center">
                      <img
                        src="@/assets/images/inprogress/logos/LOGO_SEDENA.png"
                        class="img-fluid"
                        >
                    </div>
                  </div>
                 </div>
               </div>
               <!-- informacion -->
            </div>
          </div>
        </section>
        <section id="Tramo-5-sur">
          <div class="container">
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
              <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Catenaria del Tren Maya Tramo 5 Sur - subtramo C</h1>
            </div>
            </div>
            <div class="row h-100">
              <!-- galeria -->
               <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <div id="carouseltramo5sur" class="carousel slide carousel-fade" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active"><img src="@/assets/images/inprogress/tramo-5-sur/01.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/tramo-5-sur/02.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/tramo-5-sur/03.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/tramo-5-sur/04.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/tramo-5-sur/05.webp" alt="" class="img-fluid"></div>
                  </div>
                  <div class="carousel-indicators">
                    <button
                    type="button"
                    data-bs-target="#carouseltramo5sur"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouseltramo5sur"
                    data-bs-slide-to="1"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouseltramo5sur"
                    data-bs-slide-to="2"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouseltramo5sur"
                    data-bs-slide-to="3"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouseltramo5sur"
                    data-bs-slide-to="4"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  </div>
                </div>
               </div>
               <div class="col descripcion">
                <div class="col m-5">
                  <h3>Descripción del Proyecto</h3>
                  <p>EL objeto del contrato es el suministro e instalacion del Sistema de Catenaria del Tramo 5 Sur
                    del Tren Maya:
                  </p>
                  <br>
                  <p>Postes, pórtico en estaciones, pruebas e instalacion de ménsulas, tendido e instalación de hilos,
                    cables, montaje, señales de seguridad, aisladores, aparatos, ajuste de seccionamiento catenaria,
                    desarrollo de pruebas de puesta en marcha y legalización de todas las instalaciones.
                  </p>
                  <h3>El proyecto en cifras</h3>
                  <div class="row align-content-center text-center justify-content-center">
                    <div class="col-lg-4 col-4 mt-3">
                      <h1 class="m-0 fw-semibold">20</h1>
                      <h3 class="fw-bold">KM TRAMO 5 SUR</h3>
                    </div>
                    <div class="col-5 col-lg-4 mt-3">
                      <h1 class="m-0 fw-semibold">417</h1> 
                      <h3 class="fw-semibold fs-4">MDP [MXN]</h3>
                    </div>
                    <div class="col-12 col-lg-3 mt-3 align-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_ICA.png"
                        class="img-fluid">
                    </div>
                  </div>
                </div>
               </div>
            </div>
          </div>
        </section>
        <section id="Lecheria-AIFA">
          <div class="container ">
            <div class="row">
            <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Sistema de Energía y SETE Ampliación Suburbano Lechería-AIFA</h1>
            </div>
            </div>
            <div class="row h-100">
              <!-- galeria -->
               <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <div id="carouselaifa" class="carousel slide carousel-fade" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active"><img src="@/assets/images/inprogress/energia-sete/01.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/energia-sete/02.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/energia-sete/03.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/energia-sete/04.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/energia-sete/05.webp" alt="" class="img-fluid"></div>
                  </div>
                  <div class="carousel-indicators">
                    <button
                    type="button"
                    data-bs-target="#carouselaifa"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselaifa"
                    data-bs-slide-to="1"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselaifa"
                    data-bs-slide-to="2"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselaifa"
                    data-bs-slide-to="3"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselaifa"
                    data-bs-slide-to="4"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  </div>
                </div>
               </div>
               <div class="col descripcion">
                <div class="col m-5">
                  <h3>Descripción del Proyecto</h3>
                  <p>El objeto del contrato es la ejecución de las obras correspondientes a los siguientes sistemas: </p>
                  <ul>
                    <li>Sistemas de Energía, incluyendo: 
                      <ul>
                        <li>Subestaciones Auxiliares de Estaciones, Zona de Inspeccion y CETRAMs: 
                          <br>
                          Suministro, Montaje, Pruebas y Puesta en Marcha de los equipos que integran las subestaciones. 
                        </li>
                        <li>
                          Anillo de 23  kV - Suministro, tendido y pruebas de un circuito y un conductor por fase con cable AI 18/30 kV RHZ1-OL
                          1x240mm<sup>2</sup>.
                        </li>
                      </ul>
                    </li>
                    <li>Subestación de Tracción (SETE): Suministro, Montaje, Pruebas y Puesta en Marcha de los equipos que 
                      integran la SETE (excepto GIS y transformador de potencia, cuyo suministro es por parte de CAFTE).
                    </li>
                    <li>Centro de Transformacion del Deprimido: Suministro y montaje de equipos                      
                    </li>
                    <li>Subestaciones Auxiliares: 12.
                      <ul>
                        <li>Estaciones 1,2,3,4,5,6, AIFA, Zona de Inspección, Cetram 1,2,3 y 4.</li>
                      </ul>
                    </li>
                    <li>Anillo 23kV: 24km; Cable MT uniendo las subestaciones.
                    </li>
                    <li>Centro de Transformación del Deprimido (CT Deprimido).
                    </li>
                  </ul>
                  <h3>El proyecto en cifras:</h3>
                  <div class="row align-content-center text-center justify-content-center">
                    <div class="col-lg-3 col-12 mt-3">
                      <h1 class="m-0 fw-semibold">23.6</h1>
                      <h3 class="fw-semibold">KM</h3>
                    </div>
                    <div class="col-lg-5 col-9 my-3 align-content-center justify-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_CFA.png"
                        class="img-fluid">
                    </div>
                    <div class="col-lg-4 col-9 mt-3">
                      <h1 class="m-0 fw-semibold">359.6</h1> 
                      <h3 class="fw-semibold">MDP [MXN]</h3>
                    </div>
                  </div>
                </div>
               </div>
            </div>
          </div>
        </section>
        <section id="Tramo-5-Norte">
          <div class="container">
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Instalación de la Catenaria del frente 4 tramo 5 Norte del Tren Maya</h1>
            </div>
            </div>
            <div class="row h-100">
              <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <div id="carouseltramo5norte" class="carousel slide carousel-fade" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active"><img src="@/assets/images/inprogress/catenaria-frente-4/01.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/catenaria-frente-4/02.webp" alt="" class="img-fluid"></div>
                  </div>
                  <div class="carousel-indicators">
                    <button
                    type="button"
                    data-bs-target="#carouseltramo5norte"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouseltramo5norte"
                    data-bs-slide-to="1"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  </div>
                </div>
              </div>
              <div class="col descripcion">
                <div class="col m-5">
                  <h3>Descripción del Proyecto</h3>
                  <p>El objeto del contrato es la Instalación de la Catenaria del frente 4 tramo 5 Norte del Tren Maya, incluyendo:</p>
                  <ul>
                    <li>Arrendamiento de ferro-camión bivial y grúa con capacidad para carretes de 4500 kg.
                    </li>
                    <li>Instalación de cable de retorno aéreo y bajada de cable a cada 450 m.
                    </li>
                    <li>Instalación de hilo de contacto e hilo sustentador
                    </li>
                    <li>Instalación de feeder negativo, péndolas y conexiones, así como dispositivos de tensión mecánica
                    </li>
                    <li>Instalacion de aisladores y equipo diverso.
                    </li>
                    <li>Plazo de ejecución: 3 meses
                    </li>
                    <li>Arrendamiento de ferro-camión bivial
                    </li>
                    <li>Instalación de cable de retorno aéreo, hilo de contacto e hilo sustentador
                    </li>
                  </ul>
                  <h3>El proyecto en cifras</h3>
                  <div class="row align-content-center text-center justify-content-evenly">
                    <div class="col-lg-5 col-5 mt-3">
                      <h1 class="m-0 fw-semibold">144</h1>
                      <h3 class="fw-semibold">MDP [MXN]</h3>
                    </div>
                    <div class="col-lg-5 col-7 my-3 align-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_SEDENA.png"
                        class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Tramos-5N-6">
          <div class="container">
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
              <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Telemando de Seccionadores de Catenaria del Tren Maya Tramos 5N y 6</h1>
            </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <img 
                 src="@/assets/images/inprogress/telemando-seccionadores/01.webp" 
                 class="img-fluid"
                 >
              </div>
              <div class="col descripcion">
                <div class="col m-5">
                  <h3>Descripción del Proyecto</h3>
                  <p>El objeto del contrato es el suministro e instalación, configuración y puesta en 
                    marcha del Telemando de seccionadores de Catenaria del Tren Maya, Tramo 5 Norte y Tramo 6, incluyendo:
                  </p>
                  <ul>
                    <li>
                      Desarrollo de ingeniería de detalle del telemando de seccionadores de catenaria para ambos tramos.
                    </li>
                    <li>
                      Configuración de dispositivos de electrónicos de armario puesto local de operación de seccionadores de Catenaria PLO-CA.
                    </li>
                    <li>
                      Suministro, instalación y montaje de armarios para telemando de catenaria PLO-CA.
                    </li>
                    <li>
                      Suministro, instalación y montake de tableros de seccionadores, distribuidores ópticos y equipo diverso.
                    </li>
                  </ul>
                  <p>Cliente: Secretaria de la Defensa Nacional</p>
                  <p>Desarrollo de ingeniería de detalle del Telemando de Seccionadores Suministro, instalación y montaje</p>
                  <h3>El proyecto en cifras</h3>
                  <div class="row align-content-center text-center justify-content-evenly">
                    <div class="col-lg-4 col-6 mt-3">
                      <h1 class="m-0 fw-semibold">43.2 KM</h1>
                      <h3>Tramo 5N</h3>
                    </div>
                    <div class="col-lg-4 col-5 mt-3">
                      <h1 class="m-0 fw-semibold">255 KM</h1> 
                      <h3>Tramo 6</h3>
                    </div>
                    <div class="col-lg-4 col-6 mt-3">
                      <h1 class="m-0 fw-semibold">310.9</h1>
                      <h3>MDP [MXN]</h3>
                    </div>
                    <div class="col-lg-5 col-9 my-3 align-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_SEDENA.png"
                        class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="slt">
          <div class="container">
            <div class="row">
            <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">359 SLT Solución Congestión de Enlaces Transm GCR Nonorccid Nte. Fase 1</h1>
            </div>
          </div>
            <div class="row h-100">
              <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <img 
                  src="@/assets/images/inprogress/slt/01.webp"
                  class ="img-fluid"
                >
              </div>
              <div class="col descripcion">
                <div class="col m-5">
                <h3>Descripción del Proyecto</h3>
                <p>EL objeto del contrato es la ejecución de todas las obras necesrias para la construcción e instalación del
                  Proyecto "359 SLT Solución Congestión de Enlaces Transm GCR Noroccid Norte Fase" consistente en:
                </p>
                <ul>
                  <li>Suministro de equipos y materiales de instalación permanente.</li>
                  <li>Desarrollo y ejecución de la ingeniería civil y electromecánica.</li>
                  <li>
                    Impartición dice programas de capacitación y entrenamiento, de las siguientes Subestaciones:
                    <ul>
                      <li>S.E. Nuevo Casas Grandes (Casas Grandes, Chihuahua): 230 kV, 600 MVAr (2 Statcom +/- 300MVAr).</li>
                      <li>S.E. Seri (Hermosillo, Sonora): 230 kV, 400 MVAr, (2 Statcom +/-300 MVAr).</li>
                      <li>S.E. Mazatlán II (Mazatlán, Sinaloa): 400 kV, 600 MVAr (2 Statcon +/- 300 MVAr).</li>
                      <li>S.E. Primero de Mayo (Mazapil, Zacatecas): 400 kV, 600MVAr (2Statcon +/-300 MVAr).</li>
                    </ul>
                  </li>
                  <li>Desarrollo y ejecución de la ingeniería civil y electromecánica.</li>
                  <li>Suministro de equipos y materiales de instalación permanente</li>
                  <li>Pruebas preoperatorias</li>
                  <li>Impartición de programas de capacitación y entrenamiento.</li>
                </ul>
                <h3>El proyecto en cifras</h3>
                <div class="row align-content-center text-center">
                  <div class="col-lg-4 col-12 mt-3">
                      <h1 class="m-0 fw-semibold">20</h1>
                      <h3>MESES</h3>
                    </div>
                    <div class="col-lg-4 col-12 my-3 align-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_CFE.png"
                        class="img-fluid">
                    </div>
                    <div class="col-lg-4 col-12 mt-3">
                      <h1 class="m-0 fw-semibold">160.2</h1> 
                      <h3>MUSD [USD]</h3>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Tramo-6">
          <div class="container">
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Catenaria del Tren Maya Tramo 6</h1>
            </div>
            </div>
            <div class="row h-100">
              <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <img 
                  src="@/assets/images/inprogress/tren-maya-tramo-6/01.webp"
                  class="img-fluid"
                >
              </div>
              <div class="col descripcion">
                <div class="col m-5">
                  <h3>Descripción del Proyecto</h3>
                  <p>El objeto del contrato es el Servicio de Tendido y Tensado de los Cables de Catenaria Tramo 6 Sur
                    del Tren Maya, consistente en:
                  </p>
                  <ul>
                    <li>Montaje de cables de alimentación en pórticos de subestación.</li>
                    <li>Montaje de equipo de compensación mediante poleas y contrapesos.</li>
                    <li>Tendido y tensado de hilo sustentador e hilo contacto.</li>
                    <li>Instalación de aisladores de sección.</li>
                    <li>Montaje de seccionados mecánicos.</li>
                    <li>Tendido y tensado de feeder ACSR LAS280.</li>
                    <li>Tendido y tensado de cable retorno ACSR LA110.</li>
                    <li>Servicio de tendido y tensado de los cables de catenaria, montaje de equipos de compensación
                       y seccionados mecánicas.
                    </li>
                  </ul>
                  <h3>El proyecto en cifras</h3>
                  <div class="row align-content-center text-center">
                    <div class="col-lg-4 col-12 mt-3">
                      <h1 class="m-0 fw-semibold">256</h1>
                      <h3>KM TRAMO 6</h3>
                    </div>
                    <div class="col-lg-5 col-12 mb-3 align-content-center">
                      <img
                        src="@/assets/images/inprogress/logos/LOGO_SEDENA.png"
                        class="img-fluid"
                        >
                    </div>
                    <div class="col-lg-3 col-12 mt-3">
                      <h1 class="m-0 fw-semibold">819</h1> 
                      <h3 class="fs-5">MDP [MXN]</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer msg="¿Tienes un proyecto?" />
    </div>
</template>
<script>
import Footer from "@/es/components/common/Footer";
import ArrowService from "@/en/components/common/ArrowService";

export default {
  name: "Ejecucion",
  components: {
    Footer,
    ArrowService,
  }, 
  data(){
    return{
      proyectos:[
      {
        title:'Tren interurbano México-Toluca',
        image: '01'},
      {
        title:'Suministro',
        image: '02'
      },
      {
        title: 'Catenaria del tren maya',
        image: '03'
      },
      {
        title: 'Sistemas de Energía y SETE',
        image: '04'
      },
      {
        title: 'Instalacion de la catenaria tramo 5',
        image: '05'
      },
      {
        title: 'Telemando de Seccionadores de Catenaria',
        image: '06'
      },
      {
        title: 'Solución Congestion de Enlaces',
        image: '07'
      },
      {
        title: 'Catenaria del tren Maya Tramo 6',
        image: '08'
      }
      ],
    }
  },
  methods: {
    scrollBehavior(id) {
      let element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
};
</script>