<template>
  <div class="bg-gray-50-entia">
    <section id="contact">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="m-0 ibm-plex-sans fw-light">¿Tienes una idea en mente?</p>
            <h1 class="ibm-plex-sans fw-bold">Inicia tu proyecto ahora</h1>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col-12 col-md-6 pt-0 pt-lg-4">
            <p>
              <a
                href="mailto:contacto@entiamx.com"
                target="_blank"
                class="space-grotesk color-dark-50-entia"
              >
                contacto@entiamx.com
              </a>
            </p>
            <br />
            <p class="position-relative" style="z-index: 1">
              <a
                href="tel:5514508800"
                target="_blank"
                class="space-grotesk color-dark-50-entia mt-3"
              >
                55 1450 8800
              </a>
            </p>
          </div>
          <div class="col-12 col-md-6">
            <h3 class="color-primary-entia fw-bold ibm-plex-sans">
              Escríbenos
            </h3>
            <input
              type="text"
              name="name"
              id="name"
              class="form-control mt-4"
              :class="errorName ? 'is-invalid' : 'is-valid'"
              placeholder="Nombre"
              v-model="name"
            />
            <div class="invalid-feedback">Por favor, escriba su nombre.</div>
            <input
              type="email"
              name="email"
              id="email"
              class="form-control mt-4"
              :class="errorEmail ? 'is-invalid' : 'is-valid'"
              placeholder="Correo electrónico"
              v-model="email"
            />
            <div class="invalid-feedback">
              Por favor, escriba su correo electrónico.
            </div>
            <div class="mt-4">
              <label for="message" class="form-label space-grotesk fw-light"
                >Mensaje</label
              >
              <textarea
                rows="8"
                name="message"
                id="message"
                class="form-control"
                :class="errorMessage ? 'is-invalid' : 'is-valid'"
                v-model="message"
              ></textarea>
              <div class="invalid-feedback">Por favor, escriba un mensaje.</div>
            </div>

            <div class="text-end mt-4">
              <button class="btn-entia" @click="contact">Enviar</button>
              <p class="space-grotesk mt-5 fw-light">
                Mis datos serán usados únicamente para contacto. <br />Si tienes
                dudas, consulta nuestro
                <router-link
                  to="/es/aviso-de-privacidad"
                  class="fw-bold color-dark-50-entia"
                >
                  aviso de privacidad.
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <div class="position-absolute contact-image d-none d-xl-block">
          <img
            src="@/assets/images/contact/entia-contact-bg-isolated.webp"
            alt="contact-img"
            class="img-fluid"
          />
        </div>
      </div>
    </section>
    <section id="presencia" class="bg-dark-50-entia">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0">Presencia</h1>
            <h1>Nacional</h1>
          </div>
          <div class="col-lg-4 pt-4"></div>
          <div class="col-12 col-lg-8 pt-4">
            <div class="row">
              <div class="col-12 col-lg-6">
                <h3>Ciudad de México</h3>
                <p>
                  Av. Paseo de la Reforma 243 Piso 19 Torre Mapfre Col.
                  Renacimiento, Alc. Cuauhtémoc Ciudad de México, 06500.
                </p>
              </div>
              <div class="col-12 col-lg-6">
                <h3>Toluca</h3>
                <p>
                  Nave G. Proyecto Industrial "O'Donnell Logistics Center
                  Toluca" P.K. 53.9 Carretera México - Toluca. Zona Industrial
                  Doña Rosa. Colonia Parque Industrial Lerma. CP. 52000
                  Municipio de Lerma, Estado de México.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer msg="¿Tienes un proyecto?" />
    <div
      class="modal fade"
      id="contactModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="contactModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-5">
            <div class="row text-center">
              <div class="col-12">
                <h3 class="fw-bold fs-2 ibm-plex-sans" v-html="titleModal"></h3>
                <p class="fs-4 ibm-plex-sans" v-html="messageModal"></p>
                <button
                  type="button"
                  class="btn-entia btn-blue-entia ibm-plex-sans"
                  data-bs-dismiss="modal"
                >
                  ACEPTAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/es/components/common/Footer";
import bootstrap from "bootstrap/dist/js/bootstrap";
import axios from "axios";

export default {
  name: "Contact",
  components: {
    Footer,
  },
  data() {
    return {
      name: null,
      email: null,
      message: null,
      messageModal: "",
      titleModal: "",
      errorName: false,
      errorEmail: false,
      errorMessage: false,
    };
  },
  methods: {
    contact() {
      const name = this.name;
      const email = this.email;
      const message = this.message;
      let myModal = new bootstrap.Modal(
        document.getElementById("contactModal")
      );

      if (name && email && message) {
        let data = {
          name: name,
          email: email,
          message: message,
        };

        axios({
          method: "POST",
          url: "https://us-central1-bamf-85178.cloudfunctions.net/sendEmailEntia", // http://localhost:5001/bamf-85178/us-central1/sendEmailEntia https://us-central1-bamf-85178.cloudfunctions.net/sendEmailEntia
          data: data,
        })
          .then((response) => {
            if (response.status === 200) {
              myModal.show();
              this.titleModal = "Enviado";
              this.messageModal =
                "¡Gracias! Su mensaje ha sido enviado. Pronto nos pondremos en contacto contigo.";
              this.name = null;
              this.email = null;
              this.message = null;
            } else {
              myModal.show();
              this.titleModal = "Error";
              this.messageModal = response.statusText;
              this.name = null;
              this.email = null;
              this.message = null;
            }
          })
          .catch((e) => {
            console.error(e);
            myModal.show();
            this.titleModal = "Error";
            this.messageModal = "Error al enviar mensaje.";
          });
      }

      if (!name) {
        this.errorName = true;
      } else {
        this.errorName = false;
      }

      if (!email) {
        this.errorEmail = true;
      } else {
        this.errorEmail = false;
      }

      if (!message) {
        this.errorMessage = true;
      } else {
        this.errorMessage = false;
      }
    },
  },
};
</script>

<style></style>
