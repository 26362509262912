<template>
  <div>
    <section id="ferroviaria-carrusel" class="bg-gray-50-entia">
      <div class="container">
        <div class="row p-bottom">
            <div class="col-12">
              <router-link to="/es/portafolio" class="link-back">&lt; Portafolio</router-link>
            </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-12 position-relative">
          <div id="portfolio-slider">
            <div id="portfolio-slides">
              <div class="slide" v-for="item in items" :key="item.id">
                <img
                  v-bind:src="getImgUrl(item.img)"
                  width="400"
                  height="350"
                />
              </div>
            </div>
            <div id="left-arrow">
              <a class="btn" v-on:click="scrollLeft">
                <img src="@/assets/images/common/Arrow-L.svg" alt="" />
              </a>
            </div>
            <div id="center-line"></div>
            <div id="right-arrow" v-on:click="scrollRight">
              <a class="btn">
                <img src="@/assets/images/common/Arrow-R.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="row g-0">
          <div class="d-grid justify-content-center">
            <h5 class="text-center space-grotesk">04</h5>
            <h4 class="text-center ibm-plex-sans">Carreteras y edificación</h4>
          </div>
          <div class="d-md-flex text-center justify-content-md-between navbuttons">
            <div>
              <button
                @click="
                  $router.push(
                    '/es/portafolio/generacion-de-energia-y-medio-ambiente'
                  )
                "
                class="mb-3 mb-md-0"
              >
                Categoría anterior
              </button>
            </div>
            <div>
              <button
                @click="
                  $router.push(
                    '/es/portafolio/lineas-de-transmision-y-distribucion-electrica'
                  )
                "
              >
                Categoría siguiente
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer msg="Tenemos la experiencia que buscas." />
  </div>
</template>

<script>
import Footer from "@/es/components/common/Footer";

export default {
  name: "InfraestructurasFerroviarias",
  components: {
    Footer,
  },
  methods: {
    scrollLeft() {
      this.sideScroll(
        document.getElementById("portfolio-slider"),
        "left",
        10,
        450,
        10
      );
    },
    scrollRight() {
      this.sideScroll(
        document.getElementById("portfolio-slider"),
        "right",
        10,
        450,
        10
      );
    },
    getImgUrl(item) {
      var images = require.context(
        "@/assets/images/portfolio/03Carreterasyedificacion/",
        false,
        /\.webp$/
      );
      return images("./" + item + ".webp");
    },
    sideScroll(element, direction, speed, distance, step) {
      let scrollAmount = 0;
      let next = 0;

      let slideTimer = setInterval(function () {
        if (direction === "left") {
          next = element.scrollLeft - step;
          element.scrollLeft = next;
        } else {
          next = element.scrollLeft + step;
          element.scrollLeft = next;
        }

        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    },
  },
  data: function () {
    let items = [];
    for (var i in [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
      items.push({
        id: i,
        img: "Entia-Carreteras-y-edificaciones-" + parseInt(1 * i + 1),
      });
    }

    return { items: items };
  },
};
</script>

<style lang="scss" scoped>
#ferroviaria-carrusel {
  padding-top: 99px;

  & .p-bottom {
    padding-bottom: 99px;
  }

  & .link-back {
    font-family: "Space Grotesk";
    color: #463d9c;

    &:hover {
      color: #FD6900;
    }
  }
}
img {
  object-fit: cover;
}
#portfolio-slider {
  height: 450px;
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
#portfolio-slider::-webkit-scrollbar {
  display: none;
}
#portfolio-slides {
  height: 350px;
  width: max-content;
}
.slide {
  display: inline-block;
  margin-right: 50px;
}
h4 {
  font-family: "IBM Plex Sans";
  font-size: 42px;
}
h5 {
  font-family: "Space Grotesk";
  font-size: 22px;
}
.navbuttons {
  margin-bottom: 30px;
  margin-top: 50px;
  font-family: "Space Grotesk";
}
.navbuttons > div {
  margin-left: 10%;
  margin-right: 10%;
  filter: drop-shadow(0px 0px 1px black);
}
.navbuttons > div button:hover {
  background: #000;
  color: #fff;
}
.navbuttons > div button {
  background: #fff;
  height: 35px;
  width: 200px;
  border: none;
  clip-path: polygon(
    15px 0%,
    calc(100% - 10px) 0%,
    100% 0px,
    100% calc(100% - 15px),
    calc(100% - 20px) 100%,
    0px 100%,
    0% calc(100% - 5px),
    0% 15px
  );
}

#left-arrow {
  position: absolute;
  top: 35%;
}
#right-arrow {
  position: absolute;
  top: 35%;
  right: 0;
}
#center-line {
  border-radius: 5px;
  position: absolute;
  width: 350px;
  height: 5px;
  background: #463d9c;
  bottom: 60px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
</style>
