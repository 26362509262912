<template>
  <div>
    <section id="intro" class="position-relative">
      <div class="container">
        <div class="row vh-100 align-items-center">
          <div class="col-12 col-lg-6">
            <h1 class="m-0">Experience builds</h1>
            <h1><strong>future</strong></h1>

            <p class="my-4">
              <span class="color-primary-entia fw-bold">ENTIA</span> is a
              Mexican construction and engineering company specializing in
              railway infrastructure, electrical systems, transmission lines and
              substations, as well as roads and building projects.
            </p>

            <button
              class="btn-entia btn-blue-entia"
              @click="$router.push('/en/contact-us')"
            >
              Contact
            </button>
          </div>
          <div class="col-12 d-lg-none text-center">
            <img
              src="@/assets/images/home/intro-bg.webp"
              alt="intro-bg"
              class="img-fluid mt-3"
              width="223"
            />
          </div>
        </div>
        <a
          href="https://www.linkedin.com/company/"
          target="_blank"
          class="lk-icon position-absolute"
        >
          <img
            src="@/assets/images/social/linkedin-entia.svg"
            alt=""
            class="img-fluid"
          />
        </a>
        <a
          href="https://www.facebook.com/"
          target="_blank"
          class="fb-icon position-absolute"
        >
          <img
            src="@/assets/images/social/facebook-entia.svg"
            alt="fb-icon"
            class="img-fluid"
          />
        </a>
      </div>
    </section>
    <section id="servicios-inicio">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0">Our</h1>
            <h1 class="pe-3"><strong>services</strong></h1>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col-12 col-lg-6 service-2 mb-3">
            <img
              src="@/assets/images/home/lineas-de-transmision-electrica.webp"
              alt="infraestructura-ferroviara"
              class="img-fluid"
            />
            <div class="row">
              <div class="col-12 my-3">
                <router-link
                  :to="{ path: 'en/portfolio', hash: '#portafolio-transmision' }"
                  class="text-decoration-none"
                >
                  <Arrow
                    service="Electricity transmission <br/>&amp; electrical line distribution"
                  />
                </router-link>
              </div>
              <div class="col-12">
                <p>
                  The important capabilities in this activity are evidenced by
                  the execution of more than 5,000 km of transmission lines
                  throughout the last 25 years.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 service-1 mb-3">
            <img
              src="@/assets/images/home/infraestructura-ferroviaria.webp"
              alt="infraestructura-ferroviara"
              class="img-fluid"
            />
            <div class="row">
              <div class="col-12 my-3">
                <router-link
                  :to="{ path: 'en/portfolio', hash: '#portafolio-ferroviarias' }"
                  class="text-decoration-none"
                >
                  <Arrow service="Railway <br/>Infrastructure" />
                </router-link>
              </div>
              <div class="col-12">
                <p>
                  We offer extensive experience in the implementation and
                  installation of tracks, catenaries, and electrical systems in
                  railway infrastructures.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 service-4 mt-3">
            <img
              src="@/assets/images/home/generacion-energia.webp"
              alt="generacion-energia"
              class="img-fluid"
            />
            <div class="row">
              <div class="col-12 my-3">
                <router-link
                  :to="{ path: 'en/portfolio', hash: '#portafolio-energia' }"
                  class="text-decoration-none"
                >
                  <Arrow
                    service="Power generation <br/>and environmental projects"
                  />
                </router-link>
              </div>
              <div class="col-12">
                <p>
                  The generation of energy together with environmental works has
                  always been part of our identity and commitment to society. To
                  this end, we have experience in both thermoelectric and
                  combined cycle generation projects, as well as renewable
                  energy in photovoltaic plants and wind farms.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 service-3 mt-3">
            <img
              src="@/assets/images/home/carreteras-edificacion.webp"
              alt="infraestructura-ferroviara"
              class="img-fluid"
            />
            <div class="row">
              <div class="col-12 my-3">
                <router-link
                  :to="{ path: 'en/portfolio', hash: '#portafolio-infraestructura' }"
                  class="text-decoration-none"
                >
                  <Arrow service="Highways <br/>and building projects" />
                </router-link>
              </div>
              <div class="col-12">
                <p>
                  We have developed relevant competencies in the construction of
                  this type of infrastructure through the execution of projects
                  in highways and roads, as well as public facilities in
                  different states in Mexico.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="portafolio">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0">
              <strong>Our <br />portfolio</strong>
            </h1>
          </div>
          <div class="col-12 mt-5">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: 'en/portfolio', hash: '#portafolio-transmision' }"
            >
              Electricity Transmission and Distribution Lines
              <span class="ms-auto">01</span>
            </router-link>
          </div>
          <div class="col-12 mt-3">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: 'en/portfolio', hash: '#portafolio-ferroviarias' }"
            >
              Railway Infrastructure
              <span class="ms-auto">02</span>
            </router-link>
          </div>
          <div class="col-12 mt-3">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: 'en/portfolio', hash: '#portafolio-energia' }"
            >
              Power Generation and Environment
              <span class="ms-auto">03</span>
            </router-link>
          </div>
          <div class="col-12 mt-3">
            <router-link
              class="d-flex align-items-center"
              :to="{ path: 'en/portfolio', hash: '#portafolio-infraestructura' }"
            >
              Roads and Building Projects
              <span class="ms-auto">04</span>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <Newsletter />
    <Footer msg="Got a project in mind?" />
  </div>
</template>

<script>
import Footer from "@/en/components/common/Footer";
import Newsletter from "@/en/components/home/Newsletter";
import Arrow from "@/en/components/common/Arrow";

export default {
  name: "Home",
  components: {
    Footer,
    Newsletter,
    Arrow,
  },
};
</script>

<style lang="scss" scoped>
#newsletter {
  ::-webkit-input-placeholder {
    text-align: center;
    color: #ededed;
    font-weight: 300;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
    color: #ededed;
    font-weight: 300;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
    color: #ededed;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    text-align: center;
    color: #ededed;
    font-weight: 300;
  }
}
</style>
