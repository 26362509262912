<template>
  <div id="app">
    <HeaderES v-if="language == 'es'" />
    <HeaderEN v-else />
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import HeaderES from "./es/components/common/Header";
import HeaderEN from "./en/components/common/Header";

export default {
  data () {
    return {
      language: 'es'
    };
  },
  components: { HeaderES, HeaderEN },
  mounted () {
    const path = this.$route.path.split("/")[1];
    if (path != 'es') {
      this.language = 'en';
    } else {
      this.language = 'es'
    }
  }
};
</script>

<style lang="scss">
@import "assets/scss/app";
</style>
