<template>
  <div>
    <div class="container">
      <div class="row py-5 justify-content-center">
        <div class="col-12 col-lg-6 table-responsive text-center table-emails">
          <table class="table text-center">
            <thead>
              <tr>
                <th scope="col">Emails</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(email, index) in emails"
                :key="index"
                class="bg-white borderTables"
              >
                <td>{{ email.email }}</td>
              </tr>
            </tbody>
          </table>
          <button
            class="btn-entia"
            @click.prevent="downloadCSV('emails_newsletter.csv')"
          >
            {{
              $route.path.split("/")[1] == "es"
                ? "Descargar CSV"
                : "Download CSV"
            }}
          </button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/es/components/common/Footer";

export default {
  name: "NewsletterDB",
  components: {
    Footer,
  },
  data() {
    return {
      emails: [],
    };
  },
  mounted() {
    this.$database
      .ref("emails/")
      .get()
      .then((snapshot) => {
        this.emails = snapshot.val();
      })
      .catch((e) => {
        console.error(e);
      });
  },
  methods: {
    downloadCSV(filename) {
      let data = "";
      const tableData = [];
      const rows = document.querySelectorAll(".table-emails tr");
      document
        .querySelectorAll(".table-emails span")
        .forEach((e) => e.remove());

      for (const row of rows) {
        const rowData = [];
        for (const [index, column] of row
          .querySelectorAll("th, td")
          .entries()) {
          if ((index + 1) % 3 === 0) {
            rowData.push('"' + column.innerText + '"');
          } else {
            rowData.push(column.innerText);
          }
        }
        tableData.push(rowData.join(","));
      }

      data += tableData.join("\n");
      const a = document.createElement("a");
      a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
      a.setAttribute("download", filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-entia {
  min-height: 40px;
  width: 160px;
  color: #fafafa;
  font-weight: bold;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}
</style>