<template>
  <div>
    <section id="privacy">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h6 class="fw-light">ENTIA de México, S.A de C.V.</h6>
            <h4 class="ibm-plex-sans fw-bold">Privacy Policy</h4>
            <img
              src="@/assets/images/common/bullet.svg"
              alt="bullet"
              class="img-fluid mt-4 mb-5"
            />
          </div>
          <div class="col-12">
            <p>
              In compliance with the provisions of the Federal Law Protecting
              Personal Information Held by Individuals, we disclose the
              following:
            </p>
            <p>
              <span class="fw-bold">PARTY RESPONSIBLE.</span> ENTIA de México,
              S.A. de C.V., (hereinafter “ENTIA”), whose official address is 243
              Paseo de la Reforma Avenue, Floor 19, Renacimiento Neighborhood,
              Cuauhtémoc Division, Mexico City, 06500, is responsible for the
              Personal Information provided, in accordance with the applicable
              legal norms.
            </p>
            <p>
              <span class="fw-bold">CONFIDENTIAL INFORMATION.</span>
              “Confidential Information” is understood as all written, spoken,
              and graphical information, as well as the content of electronic or
              electromagnetic media, that may be exchanged by the parties, legal
              entities, natural persons, contractors, and/or subcontractors
              which may be provided, which given its nature should be considered
              confidential.
            </p>
            <p>
              The information that is submitted to ENTIA, shall be safeguarded,
              protected, and preserved, the latter in accordance with the
              provisions of the existing legislation and ENTIA’s internal
              policies for the purpose of keeping said information confidential.
            </p>
            <p>
              <span class="fw-bold">PERSONAL INFORMATION.</span> For the
              purposes mentioned below, Confidential Information and/or Personal
              Information will be considered to include, but not be limited to,
              the following:
            </p>
            <ol type="I" class="mx-3 mx-lg-5">
              <li>Identifying Information</li>
              <li>Contact Information</li>
              <li>Property Information</li>
              <li>Sensitive Information</li>
              <li>Academic Information</li>
              <li>Employment and Social Security Information</li>
              <li>
                We also collect photographs, images, and documents in reference
                to the following: identification and contact information, legal
                ones related to employment and social security issues, those
                such that verify their working or professional capacity, studies
                of legal authorization to carry out a particular activity.
              </li>
            </ol>
            <p class="mt-5">
              <span class="fw-bold">OBJECTIVES.</span> The Holder of the
              information provided and ENTIA recognize that the Confidential and
              Personal Information that any of the Parties, at its sole
              discretion, provides to the other, are for the purposes of
              managing the relationship established with us and for the
              following objectives:
            </p>
            <ul class="mx-3 mx-lg-5">
              <li>
                Identification and contact information, together with updates
                when they are modified in any way.
              </li>
              <li>
                Integration, analysis, and presentation of the participation and
                services that we have during the process and infrastructure,
                public works, and private projects.
              </li>
              <li>
                Manage information in trade and contractual relationships.
              </li>
              <li>
                Comply with legal obligations in the commercial, fiscal,
                administrative, accounting, civil protection, regulatory, and
                contractual areas which ENTIA is responsible for and those which
                are applicable.
              </li>
            </ul>
            <p class="mt-5">
              <span class="fw-bold">TREATMENT AND TEMPORALITY.</span> To comply
              with the purposes described in this Policy, the Confidential and
              Personal Information can be transferred and handled within and
              outside of the country to:
            </p>
            <ol type="I" class="mx-3 mx-lg-5">
              <li>
                Federal and local public administration authorities and
                institutions, of any kind, in order to comply with ENTIA's
                legal, accounting, and regulatory obligations and with the terms
                and conditions of the legal relationship which exists or shall
                come into existence with the Holder of the information and the
                obligations derived from said relationship, even after the
                termination of it.
              </li>
              <li>
                Service providers, business partners, or external advisors who
                need to know the information and accept the commitment to keep
                it strictly confidential and safe, in order to comply with the
                terms and conditions of the service relationship or in general
                of the commercial or legal relationship that exists or shall
                come into existence with the Holder of the information and the
                obligations subsequent to its termination.
              </li>
            </ol>
            <p class="mt-5">
              Likewise, the temporality of the handling of personal data and
              information shall depend on the legal relationship that is entered
              into with ENTIA, as well as those required by current legislation,
              relevant authorities, and ENTIA's internal policies.
            </p>
            <p>
              In the event that the personal data and information safeguarded
              shall be required by any kind of authority or that they must be
              submitted to the latter in accordance with current legislation,
              this information shall be made available to the Holder of the
              information in strict compliance with the law.
            </p>
            <p>
              <span class="fw-bold"> MODIFICATION TO THE PRIVACY POLICY.</span>
              ENTIA reserves the right to modify the present Privacy Policy in
              order to adapt it to legislative, jurisprudential, regulatory, or
              commercial practice modifications. In such case, the pertinent
              changes shall be announced on the website
              <a href="https://www.entiamx.com/en" target="_blank">
                www.entiamx.com </a
              >.
            </p>
            <p>
              The present Privacy Policy is governed by the current and
              applicable legislation in the United States of Mexico; any dispute
              arising due to its application is the responsibility of the
              National Institute of Transparency, Information Access, and
              Personal Information Protection (INAI) or the relevant legal
              authorities of Mexico City.
            </p>
          </div>
        </div>
      </div>
    </section>
    <Footer msg="Got a project in mind?" />
  </div>
</template>

<script>
import Footer from "@/en/components/common/Footer";

export default {
  name: "Contact",
  components: { Footer },
};
</script>

<style></style>
