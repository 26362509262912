var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:_vm.$route.name === 'Home' ? 'bg-dark-50-entia' : 'bg-gray-50-entia',attrs:{"id":"newsletter"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-12"},[_c('h3',{class:_vm.$route.name === 'Home'
              ? 'color-gray-entia'
              : 'color-dark-50-entia'},[_vm._v(" Suscríbete a nuestro newsletter ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control border-start-0 border-end-0 border-top-0 mx-auto input-newsletter",class:_vm.$route.name === 'Home'
              ? 'color-gray-entia'
              : 'color-dark-50-entia',style:(_vm.$route.name === 'Home'
              ? 'border-bottom-color: #EDEDED'
              : 'border-bottom-color: #463d9c'),attrs:{"type":"email","name":"newsletter","id":"newsletter-input","placeholder":"correo@ejemplo.com.mx","required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('button',{staticClass:"btn-entia",on:{"click":_vm.writeEmail}},[_vm._v("ENVIAR")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }