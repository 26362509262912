<template>
  <div>
    <img
      src="@/assets/images/common/simbolo-outline.svg"
      alt="simbolo"
      class="img-fluid symbol position-fixed d-none d-lg-block"
    />
    <section id="intro-acerca">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="m-0">Sobre</h1>
            <h1><strong>Nosotros</strong></h1>
          </div>
          <div class="col-12 col-md-6">
            <h4>
              Nuestra <span class="color-primary-entia">visión</span> es generar
              bienestar social y valor económico a través de nuestras obras y
              proyectos.
            </h4>
          </div>
          <div class="col-md-6"></div>
          <div class="col-12 col-lg-6 mb-1">
            <h6>NUESTRA MISIÓN</h6>
            <p class="mb-5 es">
              ENTIA es una empresa de capital mexicano especializada en la
              ejecución de proyectos de infraestructura, enfocada principalmente
              en líneas de transmisión y distribución, sistemas ferroviarios,
              electricidad, carreteras y edificación en general. ENTIA inicia formalmente su 
              actividad a principios de 2022 tras
              la adquisición de la empresa filial mexicana perteneciente a una
              antigua multinacional ya desaparecida.
              Nacemos por tanto con el entusiasmo y empuje de una empresa
              joven, pero contando con la experiencia de 30 años de actividad
              construyendo infraestructuras en México. Nuestra cultura se basa en el firme compromiso con todos
              los involucrados en la actividad de la empresa, el respeto por la
              conservación del medio ambiente y con el desarrollo de la sociedad
              en general.
            </p>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-12 col-lg-4 mt-4 mt-lg-0">
            <h5 class="ibm-plex-sans ps-4 mb-3">
              <strong class="fw-bold">ENTIA</strong> significa
            </h5>
            <ul>
              <li>Excelencia</li>
              <li>Eficiencia</li>
              <li>Eficacia</li>
              <li>Ética</li>
              <li>Equidad</li>
              <li>Entrega</li>
              <li>Esfuerzo</li>
              <li>Entusiasmo</li>
              <li>Emprendimiento</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="cifras" class="bg-dark-50-entia card border-0 rounded-0">
      <div class="row g-0 align-items-center">
        <div
          class="col-12 col-md-4 text-center text-xl-start d-none d-md-block"
        >
          <img
            src="@/assets/images/about/cifras-experiencia.webp"
            class="img-fluid"
            alt="cifras-experiencia"
          />
        </div>
        <div class="col-12 col-md-8">
          <div class="card-body">
            <img
              src="@/assets/images/common/bullet.svg"
              class="img-fluid"
              alt="cifras-experiencia"
            />
            <h1 class="mb-4">Cifras que se <br />traducen en éxito.</h1>
            <div class="row row-cols-2 row-cols-xl-4">
              <div class="col">
                <figure class="item">
                  <h2 class="m-0 count" number="30">0</h2>
                  <figcaption class="caption">
                    <small>Años de experiencia</small>
                  </figcaption>
                </figure>
              </div>
              <div class="col">
                <figure class="item">
                  <h2 class="m-0 count" number="60">0</h2>
                  <figcaption class="caption">
                    <small>Proyectos finalizados</small>
                  </figcaption>
                </figure>
              </div>
              <div class="col">
                <figure class="item">
                  <h2 class="m-0 count" number="5000">0</h2>
                  <figcaption class="caption">
                    <small>Km de Líneas de transmisión</small>
                  </figcaption>
                </figure>
              </div>
              <div class="col">
                <figure class="item">
                  <h2 class="m-0 count" number="3000">0</h2>
                  <figcaption class="caption">
                    <small>Millones US en inversión</small>
                  </figcaption>
                </figure>
              </div>
            </div>
            <button
              class="btn-entia btn-blue-entia mt-5 p-0"
              @click="$router.push('/portafolio')"
            >
              PORTAFOLIO
            </button>
          </div>
        </div>
      </div>
    </section>
    <Newsletter />
    <Footer msg="Ya sabes acerca de nosotros, cuéntanos tu idea." />
  </div>
</template>

<script>
import Footer from "@/es/components/common/Footer";
import Newsletter from "@/es/components/home/Newsletter";

export default {
  name: "About",
  components: {
    Footer,
    Newsletter,
  },
  mounted() {

    if (this.$route.name == "About") {
      window.addEventListener("scroll", () => {
        const element = document.getElementById("cifras") || undefined;

        if (element) {
          const position = element.getBoundingClientRect();
          const counters = document.querySelectorAll(".count");
          const speed = 3500;

          // position.top < window.innerHeight && position.bottom >= 0 <- "Element is partially visible in screen"
          // position.top >= 0 && position.bottom <= window.innerHeight <- "Element is fully visible in screen"

          if (position.top >= 0 && position.bottom <= window.innerHeight) {
            counters.forEach((counter) => {
              const animate = () => {
                const value = +counter.getAttribute("number");
                const data = +counter.innerText;
                const time = value / speed;

                if (data < value) {
                  counter.innerText = `+${Math.ceil(data + time).toLocaleString(
                    "en-US"
                  )}`;
                  setTimeout(animate, 15);
                } else {
                  counter.innerText = `+${value.toLocaleString("en-US")}`;
                }
              };

              animate();
            });
          }
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
#newsletter {
  position: relative;
  z-index: 2;
  ::-webkit-input-placeholder {
    text-align: center;
    color: #463d9c;
    font-weight: 300;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
    color: #463d9c;
    font-weight: 300;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
    color: #463d9c;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    text-align: center;
    color: #463d9c;
    font-weight: 300;
  }

  & button {
    font-weight: bold;
    letter-spacing: 1.8px;
    min-height: 40px;
    width: 138px;
    font-size: 24px;
  }
}

.footer {
  position: relative;
  z-index: 2;
}
</style>
