<template>
  <div class="bg-gray-50-entia">
    <section id="proyectos">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-12">
            <p class="mb-1 space-grotesk fw-light">
              Amplia y probada experiencia.
            </p>
            <h1 class="ibm-plex-sans fw-bold mb-4">
              Construcción de infraestructura
            </h1>
            <img
              src="@/assets/images/common/bullet.svg"
              alt="bullet"
              class="img-fluid"
            />
          </div>
          <div class="col-12 col-lg-6 mt-3">
            <p class="space-grotesk fw-light">
              Nuestro <span class="fw-bold">portafolio</span> es el resultado del profesionalismo 
              de cada colaborador y de asombrosos clientes que tomaron una gran decisión
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-5 proyecto-transmision" >
            <a
            class="d-flex align-items-center proyectos"
            @click="scrollBehavior('portafolio-transmision')"
          >
              Transmisión y Distribución
              <span class="ms-auto">01</span>
          </a>
          </div>
          <div class="col-12 mt-3 proyecto-electrica">
            <a
            class="d-flex align-items-center proyectos"
            @click="scrollBehavior('portafolio-ferroviarias')"
          >
              Infraestructuras Ferroviarias
              <span class="ms-auto">02</span>
          </a>
          </div>
          <div class="col-12 mt-3 proyecto-carretera">
            <a
            class="d-flex align-items-center proyectos"
            @click="scrollBehavior('portafolio-energia')"
          >
              Generación de energía y medio ambiente
              <span class="ms-auto">03</span>
          </a>
          </div>
          <div class="col-12 mt-3 proyecto-energia">
            <a
            class="d-flex align-items-center proyectos"
            @click="scrollBehavior('portafolio-infraestructura')"
          >
              Infraestructura y Edificación
              <span class="ms-auto">04</span>
          </a>
          </div>
        </div>
        <!-- <div class="row mt-5 pt-5" id="transmision">
          <div class="col-12 text-center">
            <p class="space-grotesk fw-light mb-1">Te invitamos a conocer</p>
            <h1 class="ibm-plex-sans fw-bold">Nuestros proyectos</h1>
          </div>
        </div> -->
      </div>
    </section>
    <section id="portafolio-transmision" class="pb-5">
      <div class="container">
        <div class="row bg-dark-50-entia my-5">
          <div class="col-12 col-lg-7 p-0"></div>
        <div class="col-12 col-lg-5 p-5">
          <h1 class="bullet">Transmisión <br> y Distribución</h1>
          <p><span class="color-white-entia fw-bold">La transmisión eléctrica desempeña un rol clave en las necesidades actuales y 
            futuras</span> de las infraestructuras eléctricas requeridas requeridas por el crecimiento de la demanda energética de 
            nuestro país. Con más de 50 proyectos de líneas de transmisión y subestaciones desarrollados a lo largo del país,
            que representan más de <span class="color-white-entia fw-bold">5,000 kilometros de líneas,</span> los profesionales de 
            <span class="color-primary-entia fw-bold">ENTIA</span> cuentan con la experiencia y capacidad
            necesaria para una ejecución exitosa atendiendo a los requisitos más exigentes por parte de nuestros clientes.</p>
        </div>
      </div>
      </div>
      <div class="row justify-content-center p-5">
  <div class="col-12 col-lg-9">
    <div class="col text-center my-3">
      <img 
        src="@/assets/images/portfolio/entia-portafolio-transmision-distribucion-1.jpg"
        class="img-fluid"
      > 
    </div>
    <div class="row text-center">
      <p class="color-dark-50-entia">ENTIA es <span class="color-dark-50-entia fw-bold">líder en el sector de Transmisión y Distribución,</span> 
        habiendo <br>ejecutado más de 50 proyectos a lo largo de todo el país.</p>
    </div>
  </div>
</div>
      <div class="container">
        <div class="row pe-3">
          <div class="row d-flex justify-content-end">
            <div class="col-3 justify-content-end">
            <span class="arrow-container float-lg-end">
                  <button
                    class="arrow-button arrow-btn"
                    data-state=""
                    @click="moveDivRight('hs-transmision')"
                  >
                    <i></i>
                    <i></i>
                    <i></i>
                  </button>
                </span></div>
          </div>
        </div>
        <div class="row">
          <div class="row scroll-horizontal" id="hs-transmision">
        <div :class="{'col-lg-5 col-12 m-1 align-content-center':item.image, 'col-lg-3 col-8 m-1 align-content-center': !item.image}" v-for="(item,index) in transmision" :key="item.titulo">
          <div class="row h-100">
            <div v-if="item.image" :class="`col-lg-5 col-6 transmision-${index+1}`"></div>
          <div :class="{'col-lg-7 col-6 py-4':item.image, 'col-lg-12 col-12 py-4':!item.image}">
            <p class="color-dark-50-entia  text-wrap "><span class="fw-bold color-secondary-entia">{{ item.titulo }}</span></p>
            <p class="color-dark-50-entia text-wrap "><span class="fw-bold color-dark-50-entia">Cliente: </span> {{ item.cliente }}</p>
            <p class="color-dark-50-entia text-wrap "><span class="fw-bold color-dark-50-entia">Presupuesto: </span> {{ item.presupuesto }}</p>
            <p class="color-dark-50-entia "><span class="fw-bold color-dark-50-entia">Año de entrega: </span> {{ item.entrega }}</p>
          </div>
          </div>
        </div>
      </div>
        </div>
      
      </div>
    </section>
    <section id="portafolio-ferroviarias">
      <div class="container">
        <div class="row bg-dark-50-entia my-5">
          <div class="col-12 col-lg-5 p-5">
            <h1 class="bullet">Infraestructuras <br />ferroviarias</h1>
            <p>
              Estamos convencidos que
              <span>
                el transporte ferroviario es y será un medio fundamental
              </span>
              para atender de manera eficiente y sostenible las necesidades de
              movilidad de las personas y de carga en el corto, mediano y largo
              plazo en el país.
            </p>
            <p>
              Ofrecemos<span> amplia experiencia</span> en la implementación e
              instalación de vías, catenarias y sistemas eléctricos en
              infraestructuras ferroviarias.
            </p>
          </div>
          <div class="col-12 col-lg-7 p-0"></div>
        </div>
      </div>
    </section>
    <section id="portafolio-energia">
      <div class="container">
        <div class="row bg-dark-50-entia">
          <div class="col-12 col-lg-7 p-0"></div>
          <div class="col-12 col-lg-5 p-5">
            <h1 class="bullet">Generación de energía y medio ambiente</h1>
            <p>
              La generación de energía y el respeto por la conservación del
              medio ambiente constituyen dos aspectos esenciales para el
              crecimiento y desarrollo sostenible de un país. Por eso, para
              <span>ENTIA</span> representa un desafío constante el poner en
              valor nuestra experiencia acumulada en
              <span
                >proyectos de generación tanto en ciclo combinado y
                termoeléctrica</span
              >, así como en proyectos para la reducción de emisiones
              contaminantes y también en proyectos de energías renovables con
              plantas fotovoltaicas y parques eólicos.
            </p>
          </div>
        </div>
      </div>
      <div class="container my-4">
        <div class="row pe-3">
            <div class="row d-flex justify-content-end">
              <div class="col-3 justify-content-end">
              <span class="arrow-container float-lg-end">
                    <button
                      class="arrow-button arrow-btn"
                      data-state=""
                      @click="moveDivRight('hs-energia')"
                    >
                      <i></i>
                      <i></i>
                      <i></i>
                    </button>
                  </span></div>
            </div>
        </div>
        <div class="row">
            <div class="row scroll-horizontal" id="hs-energia">
              <div class="col-lg-6 col-12 me-3 my-2" v-for="(item,index) in energia" :key="item.titulo">
                <div class="row justify-content-start h-100">
                <div :class="`col-6 energia-${index+1}`"
                      data-bs-toggle="modal"
                      data-bs-target="#lightBox"
                      @click.prevent="handleImage(index+1,true)"></div>
                <div class="col-6  py-4">
                  <div class="col-12">
                    <p class="color-dark-50-entia text-start text-wrap"><span class="fw-bold color-secondary-entia">{{ item.title }}</span></p>
                  </div>
                  <div class="col-12 text-start">
                    <p class="color-dark-50-entia text-wrap mb-0"><span class="fw-bold color-dark-50-entia">Cliente: </span> {{ item.client }}</p>
                    <p class="color-dark-50-entia text-wrap my-0"><span class="fw-bold color-dark-50-entia">Presupuesto: </span> {{ item.budget }}</p>
                    <p v-if="item.capacity" class="color-dark-50-entia text-wrap my-0"><span class="fw-bold color-dark-50-entia">Capacidad de Generación: </span>{{ item.capacity }}</p>
                    <p class="color-dark-50-entia text-wrap mb-0"><span class="fw-bold color-dark-50-entia">Año de entrega: </span> {{ item.year }}</p>
                  </div>
                </div>
              </div>
              </div>         
            </div>
        </div>
        </div>
    </section>
    <section id="portafolio-infraestructura">
      <div class="container">
        <div class="row bg-dark-50-entia mb-5">
          <div class="col-12 col-lg-5 p-5">
            <h1 class="bullet">
              Carreteras y<br />
              edificación
            </h1>
            <p>
              Los sistemas carreteras son críticos para lograr
              <span>conexiones seguras y eficientes</span> entre las comunidades
              en México.
              <span
                >Hemos desarrollado y construido algunas de las más avanzadas
                autopistas del país,</span
              >
              así como carreteras de la red estatal, con inversiones por un
              importe superior a los $600 millones de dólares. Nuestros
              profesionales han participado asimismo en la ejecución de
              <span
                >importantes proyectos de carreteras a nivel
                internacional.</span
              >
              Asimismo, contamos con vasta experiencia en edificaciones de
              equipamientos públicos tales como hospitales y centros locales
              asistenciales.
            </p>
          </div>
          <div class="col-12 col-lg-7 p-0"></div>
        </div>
      </div>

      <div class="container">
        <div class="row pe-3">
            <div class="row d-flex justify-content-end">
              <div class="col-3 justify-content-end">
              <span class="arrow-container float-lg-end">
                    <button
                      class="arrow-button arrow-btn"
                      data-state=""
                      @click="moveDivRight('hs-edificacion')"
                    >
                      <i></i>
                      <i></i>
                      <i></i>
                    </button>
                  </span></div>
            </div>
        </div>
        <div class="row">
          <div class="row scroll-horizontal" id="hs-edificacion">
            <div class="col-lg-6 col-12 m-1" v-for="(item,index) in infraestructura" :key="item.titulo">
            <div class="row justify-content-start h-100">
            <div :class="`col-6 infraestructura-${index+1}`"
              data-bs-toggle="modal"
              data-bs-target="#lightBox"
              @click.prevent="handleImage(index + 1, false)"></div>
            <div class="col-6 py-4">
              <div class="col-12 my-2">
                <p class="color-dark-50-entia text-wrap"><span class="fw-bold color-secondary-entia">{{ item.title }}</span></p>
              </div>
              <div class="col-12 text-start mt-3">
                <p class="color-dark-50-entia text-wrap mb-0"><span class="fw-bold color-dark-50-entia">Cliente: </span> {{ item.client }}</p>
                <p class="color-dark-50-entia mb-0"><span class="fw-bold color-dark-50-entia text-wrap">Presupuesto: </span> {{ item.budget }}</p>
                <p class="color-dark-50-entia mb-0"><span class="fw-bold color-dark-50-entia text-wrap">Año de entrega: </span> {{ item.year }}</p>
              </div>
            </div>
            </div>
            </div>
            
          </div>
        </div>
          
        </div>
    </section>

    <section id="compania" class="bg-gray-50-entia text-center overflow-hidden">
      <div class="container position-relative">
        <img
          src="@/assets/images/common/simbolo-outline-orange.svg"
          alt="simbolo-outline"
          class="img-fluid"
        />
        <div
          class="
            row
            justify-content-center
            align-items-center
            position-absolute
          "
        >
          <div class="col-12 col-lg-8 col-xl-6">
            <p>
              <strong class="fw-bold">ENTIA</strong> es una compañía enfocada en
              la
              <strong
                >ingeniería, construcción, desarrollo y gestión de proyectos de
                infraestructura de obra pública</strong
              >
              relacionados con la transmisión, distribución y generación de
              energía, así como infraestructura ferroviaria, carretera y
              edificaciones.
            </p>
          </div>
        </div>
      </div>
    </section>
    <Footer msg="Tenemos la experiencia que buscas." />
    <!-- Modal -->
    <div
      class="modal fade"
      id="lightBox"
      tabindex="-1"
      aria-labelledby="lightBoxLabel"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog modal-dialog-centered modal-lg
          text-center
          justify-content-center
        "
      >
        <img :src="imgSelected" alt="light-box" class="img-fluid" />
      </div>
    </div>
  </div>
</template>


<script>
import Footer from "@/es/components/common/Footer";

export default {
  name: "Portfolio",
  components: {
    Footer,
  },
  data() {
    return {
      energia: [
        {
          title: "Parques Eólicos de La Mesa y Ciudad Victoria.",
          client: "Compañía Eólica La Mesa - Cd. Victoria",
          budget: "USD$ 160 millones",
          year: 2017,
        },
        {
          title: "Planta Recuperadora de Azufre, Refinería Salamanca, Gto.",
          client: "PEMEX",
          budget: "USD$ 57 millones",
          year: 2016,
        },
        {
          title: 'Central Baja California',
          client: 'CFE',
          budget: 'USD $ 174 millones',
          year: 2009
        },
        {
          title:
            "Central Térmica de Ciclo Combinado Rosarito en Baja California.",
          client: "Comisión Federal de Electricidad",
          budget: "USD$ 175 millones",
          capacity: "270 MW",
          year: 2009,
        },
        {
          title: 'Central de energía de Guerrero Negro',
          client: 'CFE',
          budget: 'USD $27 Millones',
          year: 2004
        },
        {
          title:
            "Rehabilitación y modernización de la Central Térmica Plutarco Elías Calles.",
          client: "Comisión Federal de Electricidad",
          budget: "USD$ 20 millones",
          capacity: "6x350 MW",
          year: 2009,
        },
      ],
      infraestructura: [
        {
          title: "Autopista Perote-Xalapa",
          client: "SCT",
          budget: "USD$ 340 millones",
          year: 2012,
        },
        {
          title: "Hospital Rural en San José del Rincón",
          client: "Instituto Mexicano del Seguro Social",
          budget: "USD$ 6 millones",
          year: 2011,
        },
        {
          title:
            "Estación de tratamiento de aguas residuales en Puerto Vallarta",
          client: "SEAPAL",
          budget: "USD$ 8 millones",
          year: 2011,
        },
        {
          title: "Hospital de las Culturas San Cristóbal de las Casas",
          client: "Secretaría de la Infraestructura del Gobierno de Chiapas",
          budget: "USD$ 14 millones",
          year: 2010,
        },
        {
          title: "Autopista Saltillo-Monterrey",
          client: "Concesionaria Autopista Monterrey-Saltillo",
          budget: "USD$ 280 millones",
          year: 2009,
        },
      ],
      finalizados: [
        {
          title: "Subestación Los Mochis",
          client: "CFE",
          budget: "MXN$ 36 millones y USD $ 12 Millones",
          year: 1995,
        },
        {
          title: "Subestación Sureste",
          client: "CFE",
          budget: "USD$ 45 Millones",
          year: 2001,
        },
        {
          title: "Línea de Subtransmisión Occidental",
          client: "CFE",
          budget: "USD$ 27 Millones",
          year: 2006,
        },
        {
          title: "Subestación Querétaro y Celaya",
          client: "CFE",
          budget: "MXN$ 12 millones y USD$ 97 Millones",
          year: 1995,
        },
        {
          title: "Línea Altamira ll, lll y IV",
          client: "CFE",
          budget: "USD$ 247 Millones",
          year: 2002,
        },
        {
          title: "Línea de transmisión Noroeste - Norte",
          client: "CFE",
          budget: "USD$ 120 Millones",
          year: 2004,
        },
        {
          title: "Línea de transmisión Carbón II - Lampazos",
          client: "CFE",
          budget: "MXN$ 20 millones y USD$ 7 millones",
          year: 1995,
        },
        {
          title: "Línea de transmisión Norte - Occidental",
          client: "CFE",
          budget: "MXN$ 65 millones",
          year: 2002,
        },
        {
          title: "Línea Yautepec",
          client: "CFE",
          budget: "MXN$ 72 millones y USD$ 11 millones",
          year: 2005,
        },
        {
          title: "Línea de transmisión Sureste",
          client: "CFE",
          budget: "USD$ 42 millones",
          year: 2001,
        },
        {
          title: "Central de energía de Guerrero Negro",
          client: "CFE",
          budget: "USD$ 27 millones",
          year: 2004,
        },
        {
          title: "Líneas de subtransmisión Sureste Peninsular",
          client: "CFE",
          budget: "USD$ 9 millones",
          year: 2006,
        },
        {
          title: "Líneas de transmisión y transformación del Noroeste - Norte",
          client: "CFE",
          budget: "USD$ 16 millones",
          year: 2012,
        },
        {
          title: "Línea de transmisión refinería Tula",
          client: "PEMEX",
          budget: "USD$ 160 millones",
          year: 2013,
        },
        {
          title: "Líneas de transmisión Buenavista del Cobre",
          client: "MINERA MÉXICO",
          budget: "USD$ 6 millones",
          year: 2014,
        },
        {
          title: "Líneas de transmisión Golfo Norte (2da Fase)",
          client: "CFE",
          budget: "USD$ 8 millones",
          year: 2015,
        },
        {
          title: "Subestación Buenavista",
          client: "MINERA MÉXICO",
          budget: "USD$ 7 millones",
          year: 2014,
        },
        {
          title: "Líneas de transmisión Oaxaca II, III, IV",
          client: "CFE",
          budget: "USD$ 209 millones",
          year: 2010,
        },
        {
          title: "Línea Azul de Cortés",
          client: "AZUL DE CORTÉS",
          budget: "USD$ 6 millones",
          year: 2011,
        },
        {
          title: "Línea de transmisión transformación del Noreste (3ra Fase)",
          client: "CFE",
          budget: "USD$ 44 millones",
          year: 2013,
        },
        {
          title: "Línea de transmisión ITSMO",
          client: "CFE",
          budget: "USD$ 63 millones",
          year: 2007,
        },
        {
          title: "Central Baja California",
          client: "CFE",
          budget: "USD$ 174 millones",
          year: 2009,
        },
        {
          title: "Subestación distribución Noreste",
          client: "CFE",
          budget: "USD$ 4 millones",
          year: 2013,
        },
        {
          title: "Línea de transmisión Guatemala - México",
          client: "INDE GUATEMALA",
          budget: "USD$ 16 millones",
          year: 2009,
        },
        {
          title: "Línea de transmisión transformación del Noreste (2da Fase)",
          client: "CFE",
          budget: "USD$ 16 millones",
          year: 2010,
        },
      ],
      transmision:[
        {
          titulo: 'Subestación Cuchillo Banco 2, Nuevo León',
          cliente: 'CFE',
          presupuesto: 'MXN$ 131.3 Millones',
          entrega: '2023',
          image: require(`@/assets/images/portfolio/carruselTransmision/transmision-1.jpg`)
        },
        {
          titulo: 'Líneas de transmición Golfo Norte (2da Fase)',
          cliente: 'CFE',
          presupuesto: 'USD$ 8 millones',
          entrega: '2015'
        },
        {
          titulo: 'Subestación Buenavista',
          cliente: 'MINERA MÉXICO',
          presupuesto: 'USD $ 7 millones',
          entrega: '2014',
          image: require(`@/assets/images/portfolio/carruselTransmision/transmision-3.jpg`)
        },
        {
          titulo: 'Línea de transmisión Buenavista del Cobre',
          cliente: 'MINERA MÉXICO',
          presupuesto: 'USD$ 6 millones',
          entrega: '2014'
        },
        {
          titulo: 'Subestación distribución Noreste',
          cliente: 'CFE',
          presupuesto: 'USD$ 4 millones',
          entrega: '2013'
        },
        {
          titulo: 'Línea de transmisión transformación del Noreste (3era Fase)',
          cliente: 'CFE',
          presupuesto: 'USD$ 44 millones',
          entrega: '2013'
        },
        {
          titulo: 'Línea de transmisión refinería Tula',
          cliente: 'PEMEX',
          presupuesto: 'USD$ 160 millones',
          entrega: '2013'
        },
        {
          titulo: 'Línea de transmisión y transformación del Noroeste - Norte',
          cliente: 'CFE',
          presupuesto: 'USD$ 18 millones',
          entrega: '2012'
        },
        {
          titulo: 'Línea Azul de Cortés',
          cliente: 'AZUL DE CORTÉS',
          presupuesto: 'USD$ 6 millones',
          entrega: '2011'
        },
        {
          titulo: 'Línea de transmisión Oaxaca II, III, IV',
          cliente: 'CFE',
          presupuesto: 'USD$ 209 millones',
          entrega: '2010'
        },
        {
          titulo: 'Línea de transmisión transformación del Noreste (2da Fase)',
          cliente: 'CFE',
          presupuesto: 'USD$ 16 millones',
          entrega: '2010'
        },
        {
          titulo: 'Línea de transmisión Guatemala - México',
          cliente: 'INDE GUATEMALA',
          presupuesto: 'USD$ 16 millones',
          entrega: '2009'
        },
        {
          titulo: 'Central Baja California',
          cliente: 'CFE',
          presupuesto: 'USD$ 174 millones',
          entrega: '2009'
        },
        {
          titulo: 'Línea de transmisión ITSMO',
          cliente: 'CFE',
          presupuesto: 'USD$ 63 millones',
          entrega: '2007'
        },
        {
          titulo: 'Líneas de subtransmisión Sureste Peninsular',
          cliente: 'CFE',
          presupuesto: 'USD $ 9 millones',
          entrega: '2006',
          image: require(`@/assets/images/portfolio/carruselTransmision/transmision-15.jpg`)
        },
        {
          titulo: 'Línea de Substransmisión Occidental',
          cliente: 'CFE',
          presupuesto: 'USD$ 27 millones',
          entrega: '2006'
        },
        {
          titulo: 'Línea Yautepec',
          cliente: 'CFE',
          presupuesto: 'MXN4 72 millones y USD $ 11 millones',
          entrega: '2005',
          image:  require(`@/assets/images/portfolio/carruselTransmision/transmision-17.jpg`)
        },
        {
          titulo: 'Central de energía de Guerrero Negro',
          cliente: 'CFE',
          presupuesto: 'USD$ 27 millones',
          entrega: '2004'
        },
        {
          titulo: 'Línea de transmisión Noreste - Norte',
          cliente: 'CFE',
          presupuesto: 'USD$ 120 millones',
          entrega: '2004'
        },
        {
          titulo: 'Línea Altamira II, III y IV',
          cliente: 'CFE',
          presupuesto: 'USD$ 247 millones',
          entrega: '2002'
        },
        {
          titulo: 'Línea de transmisión Norte - Occidental',
          cliente: 'CFE',
          presupuesto: 'MXN$ 65 millones',
          entrega: '2002'
        },
        {
          titulo: 'Línea de transmisión Sureste',
          cliente: 'CFE',
          presupuesto: 'USD$ 42 millones',
          entrega: '2001'
        },
        {
          titulo: 'Subestación Sureste',
          cliente: 'CFE',
          presupuesto: 'USD$ 45 millones',
          entrega: '2001'
        },
        {
          titulo: 'Subestación Los Mochis',
          cliente: 'CFE',
          presupuesto: 'MXN$ 36 millones y USD $12 millones',
          entrega: '1995'
        },
        {
          titulo: 'Subestación Querétaro y Celaya',
          cliente: 'CFE',
          presupuesto: 'MXN$ 12 millones y USD$ 97 millones',
          entrega: '1995'
        },
        {
          titulo: 'Línea de transmisión Carbón II - Lampazos',
          cliente: 'CFE',
          presupuesto: 'MXN$ 20 millones y USD$ 7 millones',
          entrega: '1995'
        }
      ],
      imgSelected: "",
      visible: 3,
    };
  },
  created () {
    setTimeout(() => {
      if (this.$route.hash !== '') {
        const hash = window.location.hash.split("#")[1];
        this.scrollBehavior(hash);
      }
    }, 1000);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    toBeShown() {
      return this.finalizados.slice(0, this.visible * 3);
    },
    totalItems() {
      return Math.ceil(this.finalizados.length / 3);
    },
  },
  methods: {
    scrollBehavior(id) {
      let element = document.getElementById(id);
      const offset = 140; 
      const elementPosition = element.getBoundingClientRect().top + window.scrollY - offset;

      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    },
    handleImage(index, flag) {
      if (flag) {
        this.imgSelected = require(`@/assets/images/portfolio/carruselEnergia/entia-portafolio-energia-carrusel-${index}.webp`);
      } else {
        this.imgSelected = require(`@/assets/images/portfolio/carruselInfraestructura/entia-portafolio-infraestructura-carrusel-${index}.webp`);
      }
    },
    nextItems() {
      if (this.visible < this.totalItems) this.visible += 3;
    },
    moveDivRight(id) {
      let next = 0;
      let step = 10;
      let speed = 10;
      let distance = 450;
      let scrollAmount = 0;
      let element = document.getElementById(id);

      let slideTimer = setInterval(function () {
        next = element.scrollLeft + step;
        element.scrollLeft = next;
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    },
  },
};
</script>

<style></style>
