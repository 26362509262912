<template>
  <div>
    <section id="privacy">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h6 class="fw-light">ENTIA de México, S.A de C.V.</h6>
            <h4 class="ibm-plex-sans fw-bold">Aviso de privacidad</h4>
            <img
              src="@/assets/images/common/bullet.svg"
              alt="bullet"
              class="img-fluid mt-4 mb-5"
            />
          </div>
          <div class="col-12">
            <p>
              Dando cumplimiento con lo dispuesto en la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares,
              hacemos de su conocimiento lo siguiente:
            </p>
            <p>
              <span class="fw-bold">RESPONSABLE.</span> ENTIA de México, S.A de
              C.V., (en lo sucesivo “ENTIA"), con domicilio en Avenida Paseo de
              la Reforma 243, Piso 19, Colonia Renacimiento, Alcaldía
              Cuauhtémoc, C.P. 06500, Ciudad de México, es responsable de sus
              Datos Personales proporcionados, de conformidad con lo establecido
              por la normatividad legal aplicable.
            </p>
            <p>
              <span class="fw-bold">INFORMACIÓN CONFIDENCIAL.</span> Se entiende
              por "información confidencial" toda aquella información escrita,
              verbal o gráfica, así como la contenida en medios electrónicos o
              electromagnéticos, que sea intercambiada entre las partes,
              personas morales, personas físicas, contratistas y/o
              subcontratistas que sean proporcionados, misma que por su
              naturaleza deba considerarse como confidencial.
            </p>
            <p>
              La información que sea entregada a ENTIA, será resguardada,
              protegida y conservada, por ésta última de acuerdo con lo
              dispuesto en la legislación vigente y las políticas internas de
              ENTIA para efectos de mantener la confidencialidad de dicha
              información.
            </p>
            <p>
              <span class="fw-bold">DATOS PERSONALES.</span> Para las
              finalidades que se mencionan más adelante serán considerados de
              manera enunciativa más no limitativa, como Información
              Confidencial y/o Datos Personales:
            </p>
            <ol type="I" class="mx-3 mx-lg-5">
              <li>Datos de identificación.</li>
              <li>Datos de contacto.</li>
              <li>Datos patrimoniales.</li>
              <li>Datos sensibles.</li>
              <li>Datos académicos.</li>
              <li>Datos laborales y de seguridad social.</li>
              <li>
                También recabamos fotografías, imágenes y documentos: de
                identificación y contacto, legales relativos a cuestiones
                laborales o de seguridad social, para acreditar su capacidad
                laboral o profesional, estudios o autorización legal para
                realizar determinada actividad.
              </li>
            </ol>
            <p class="mt-5">
              <span class="fw-bold">FINALIDADES.</span> El Titular de los datos
              proporcionados y ENTIA reconocen que la Información Confidencial y
              Datos Personales que cualquiera de las Partes a su entera
              discreción proporcione a la otra, son para propósitos de gestionar
              la relación establecida con nosotros y para las siguientes
              finalidades:
            </p>
            <ul class="mx-3 mx-lg-5">
              <li>
                Identificación y contacto, junto con su actualización cuando
                exista alguna modificación;
              </li>
              <li>
                Integración, análisis, y presentación de las participaciones y
                servicios que tengamos en procesos y proyectos de
                infraestructura, obra pública y privada.
              </li>
              <li>
                Gestionar datos en las relaciones comerciales y contractuales.
              </li>
              <li>
                Cumplir las obligaciones legales en el ámbito mercantil, fiscal,
                administrativa, contable, protección civil, regulatorias,
                contractuales a cargo de ENTIA y las que le sean aplicables.
              </li>
            </ul>
            <p class="mt-5">
              <span class="fw-bold">TRATAMIENTO Y TEMPORALIDAD.</span> Para
              cumplir con las finalidades descritas en este Aviso, la
              Información Confidencial y Datos Personales pueden ser
              transferidos y tratados dentro y fuera del país a:
            </p>
            <ol type="I" class="mx-3 mx-lg-5">
              <li>
                Autoridades y entidades de la administración pública federales o
                locales, de cualquier índole, para cumplir con obligaciones
                legales, contables y regulatorias de ENTIA y con los términos y
                condiciones de la relación jurídica que exista o llegara a
                existir con el Titular de los datos y con las obligaciones
                derivadas de dicha relación, aún después de su terminación.
              </li>
              <li>
                Prestadores de servicios, socios de negocios o asesores externos
                que necesiten conocer la información y asuman el compromiso de
                mantenerla bajo un estricto orden de confidencialidad y
                seguridad; para cumplir los términos y condiciones de la
                relación de servicios o en general de la relación comercial o
                jurídica que exista o llegara a existir con el Titular de datos
                y con las obligaciones posteriores a su terminación.
              </li>
            </ol>
            <p class="mt-5">
              Asimismo, la temporalidad del manejo de datos personales e
              información dependerá de la relación jurídica que se tenga
              celebrada con ENTIA, así como las exigidas por la legislación
              vigente, las autoridades competentes y las políticas internas de
              ENTIA.
            </p>
            <p>
              En caso de que los datos personales e información resguardados
              sean requeridos por una autoridad de cualquier índole o deban ser
              entregados a ésta última de acuerdo con la legislación vigente,
              estos datos se pondrán a disposición del Titular de datos dentro
              del estricto cumplimiento a la ley.
            </p>
            <p>
              <span class="fw-bold">
                MODIFICACIONES AL AVISO DE PRIVACIDAD.</span
              >
              ENTIA se reserva el derecho a modificar el presente Aviso de
              Privacidad para adaptarlo a novedades legislativas,
              jurisprudenciales, regulatorias o prácticas comerciales. En dicho
              supuesto, se anunciará en la página de internet
              <a href="https://www.entiamx.com/es" target="_blank">
                www.entiamx.com
              </a>
              los cambios de referencia.
            </p>
            <p>
              El presente Aviso de Privacidad se rige por la legislación vigente
              y aplicable en los Estados Unidos Mexicanos, cualquier
              controversia que se suscite con motivo de su aplicación
              corresponde al Instituto Nacional de Transparencia, Acceso a la
              Información y Protección de Datos Personales (INAI) o ante los
              Órganos Jurisdiccionales competentes en la Ciudad de México.
            </p>
          </div>
        </div>
      </div>
    </section>
    <Footer msg="¿Tienes un proyecto?" />
  </div>
</template>

<script>
import Footer from "@/es/components/common/Footer";

export default {
  name: "Contact",
  components: { Footer },
};
</script>

<style>
</style>