<template>
  <div>
    <section id="servicios">
      <div class="row g-0 scroll-row">
        <div class="col-12 col-sm-6 servicio-1 position-relative">
          <div class="position-absolute">
            <a @click="scrollBehavior('rail')" class="cursor">
              <ArrowService service="Rail <br/>Infrastructure" />
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 servicio-2 position-relative">
          <div class="position-absolute">
            <a @click="scrollBehavior('electrical')" class="cursor">
              <ArrowService
                service="Electrical Transmission <br/>and Distribution Lines"
              />
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 servicio-3 position-relative">
          <div class="position-absolute">
            <a @click="scrollBehavior('roads')" class="cursor">
              <ArrowService service="Roads <br/>and Construction" />
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 servicio-4 position-relative">
          <div class="position-absolute">
            <a @click="scrollBehavior('power-generation')" class="cursor">
              <ArrowService
                service="Power Generation <br>and the Environment"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section id="compania" class="bg-gray-50-entia text-center overflow-hidden">
      <div class="container position-relative">
        <img
          src="@/assets/images/common/simbolo-outline-orange.svg"
          alt="simbolo-outline"
          class="img-fluid"
        />
        <div
          class="
            row
            justify-content-center
            align-items-center
            position-absolute
          "
        >
          <div class="col-12 col-lg-8 col-xl-6">
            <p>
              <strong class="fw-bold">ENTIA</strong> is a company focused on the
              <strong>
                engineering, construction, development, and management of public
                works infrastructure projects,
              </strong>
              related to the transmission, distribution and generation of
              energy, as well as railway, road and building infrastructure.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="detalles-servicios">
      <div class="container">
        <div class="row bg-dark-50-entia my-5" id="rail">
          <div class="col-12 col-lg-6 p-5">
            <h1>Railway <br />infrastructure</h1>
            <p>
              We are convinced that
              <span
                >railway transportation is and will be a fundamental means
              </span>
              to efficiently and sustainably meet the mobility needs of people
              and cargo in the short, medium, and long term in the country.
            </p>
            <p>
              We offer <span>extensive experience</span> in the implementation
              and installation of track, catenary and electrical systems in
              railway infrastructures.
            </p>
          </div>
          <div class="col-12 col-lg-6 p-0"></div>
        </div>
        <div class="row bg-dark-50-entia mb-5" id="electrical">
          <div class="col-12 col-lg-6 p-0"></div>
          <div class="col-12 col-lg-6 p-5">
            <h1>Electricity transmission <br />and distribution lines</h1>
            <p>
              <span
                >Power transmission plays a key role in the current and
                future</span
              >
              needs of electrical infrastructure required by the growth of
              energy demand in our country. With more than 50 transmission line
              and substation projects developed throughout the country,
              representing more than
              <span>5,000 kilometers of lines</span>, the professionals at
              <span class="color-primary-entia fw-bold">ENTIA</span>
              have the experience and skills necessary for a successful
              execution meeting the most demanding requirements of our clients.
            </p>
          </div>
        </div>
        <div class="row bg-dark-50-entia mb-5" id="roads">
          <div class="col-12 col-lg-6 p-5">
            <h1>
              Roads and<br />
              building projects
            </h1>
            <p>
              Highway systems are critical to achieving safe,
              <span>efficient connections</span> among communities in Mexico.
              <span
                >We have developed and built some of the most advanced highways
                in the country,</span
              >
              as well as state highways, with investments of more than $600
              million dollars. Our professionals have also participated in
              <span
                >the execution of important international highway
                projects.</span
              >
              We also have vast experience in the construction of public
              facilities such as hospitals and local health care centers.
            </p>
          </div>
          <div class="col-12 col-lg-6 p-0">
            <!-- <img
              src="../assets/images/services/carreteras-y-edificacion-detalles.webp"
              alt="carreteras-y-edificacion"
              class="img-fluid"
            /> -->
          </div>
        </div>
        <div class="row bg-dark-50-entia" id="power-generation">
          <div class="col-12 col-lg-6 p-0"></div>
          <div class="col-12 col-lg-6 p-5">
            <h1>Power generation and environment</h1>
            <p>
              Energy generation and respect for environmental conservation are
              two essential aspects for the growth and sustainable development
              of a country. Therefore, for
              <span>ENTIA</span> it represents a constant challenge to enhance
              our accumulated experience in power generation in both combined
              cycle and thermoelectric projects, as well as in projects for the
              reduction of polluting emissions and in renewable energy with
              photovoltaic plants and wind farms.
            </p>
          </div>
        </div>
      </div>
    </section>
    <Footer msg="We have the infrastructure for your project." />
  </div>
</template>

<script>
import Footer from "@/en/components/common/Footer";
import ArrowService from "@/en/components/common/ArrowService";

export default {
  name: "ServicesEN",
  components: {
    Footer,
    ArrowService,
  },
  created() {
    setTimeout(() => {
      if (this.$route.hash !== '') {
        const hash = window.location.hash.split("#")[1];
        this.scrollBehavior(hash);
      }
    }, 600);
  },
  methods: {
    scrollBehavior(id) {
      let element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  },
};
</script>

<style scoped>
.cursor {
  cursor: pointer;
}
</style>
