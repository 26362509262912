<template>
  <section
    id="newsletter"
    :class="$route.name === 'HomeEN' ? 'bg-dark-50-entia' : 'bg-gray-50-entia'"
  >
    <div class="container">
      <div class="row text-center">
        <div class="col-12">
          <h3
            :class="
              $route.name === 'HomeEN'
                ? 'color-gray-entia'
                : 'color-dark-50-entia'
            "
          >
            Subscribe to our newsletter!
          </h3>
          <input
            type="email"
            name="newsletter"
            id="newsletter-input"
            placeholder="email@example.com"
            class="
              form-control
              border-start-0 border-end-0 border-top-0
              mx-auto
              input-newsletter
            "
            :class="
              $route.name === 'HomeEN'
                ? 'color-gray-entia'
                : 'color-dark-50-entia'
            "
            :style="
              $route.name === 'HomeEN'
                ? 'border-bottom-color: #EDEDED'
                : 'border-bottom-color: #463d9c'
            "
            required
            v-model="email"
          />
          <button @click="writeEmail" class="btn-entia">SUBSCRIBE</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      email: null,
    };
  },
  methods: {
    writeEmail() {
      if (this.validateEmail(this.email)) {
        this.$database.ref("emails/").push().set({
          email: this.email.trim(),
        })
        .then(() => {
          this.email = null;
        })
        .catch((e) => {
          console.error(e);
        });
      }
    },
    validateEmail(email) {
      const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return reg.test(email);
    }
  },
};
</script>

<style></style>
