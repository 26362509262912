<template>
  <div class="arrow-container">
    <span v-html="service" class="float-start"></span>
    <button class="arrow-button float-end" data-state="">
      <i></i>
      <i></i>
      <i></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    service: String,
  },
};
</script>

<style>
</style>