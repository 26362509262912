<template>
  <div>
    <section id="servicios">
      <div class="row g-0 scroll-row">
        <div class="col-12 col-sm-6 servicio-1 position-relative">
          <div class="position-absolute">
            <a @click="scrollBehavior('ferroviaria')" class="cursor">
              <ArrowService service="Infraestructuras <br/> ferroviarias" />
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 servicio-2 position-relative">
          <div class="position-absolute">
            <a @click="scrollBehavior('electrica')" class="cursor">
              <ArrowService
                service="Líneas de transmisión <br/> y distribución eléctrica"
              />
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 servicio-3 position-relative">
          <div class="position-absolute">
            <a @click="scrollBehavior('carreteras')" class="cursor">
              <ArrowService service="Carreteras <br/>y edificación" />
            </a>
          </div>
        </div>
        <div class="col-12 col-sm-6 servicio-4 position-relative">
          <div class="position-absolute">
            <a @click="scrollBehavior('generacion-energia')" class="cursor">
              <ArrowService
                service="Generación de energía <br> y medio ambiente"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
    <section id="compania" class="bg-gray-50-entia text-center overflow-hidden">
      <div class="container position-relative">
        <img
          src="@/assets/images/common/simbolo-outline-orange.svg"
          alt="simbolo-outline"
          class="img-fluid"
        />
        <div
          class="
            row
            justify-content-center
            align-items-center
            position-absolute
          "
        >
          <div class="col-12 col-lg-8 col-xl-6">
            <p>
              <strong class="fw-bold">ENTIA</strong> es una compañía enfocada en
              la
              <strong
                >ingeniería, construcción, desarrollo y gestión de proyectos de
                infraestructura de obra pública</strong
              >
              relacionados con la transmisión, distribución y generación de
              energía, así como infraestructura ferroviaria, carretera y
              edificaciones.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="detalles-servicios">
      <div class="container">
        <div class="row bg-dark-50-entia my-5" id="ferroviaria">
          <div class="col-12 col-lg-6 p-5">
            <h1>Infraestructuras <br />ferroviarias</h1>
            <p>
              Estamos convencidos que
              <span>
                el transporte ferroviario es y será un medio fundamental
              </span>
              para atender de manera eficiente y sostenible las necesidades de
              movilidad de las personas y de carga en el corto, mediano y largo
              plazo en el país.
            </p>
            <p>
              Ofrecemos<span> amplia experiencia</span> en la implementación e
              instalación de vías, catenarias y sistemas eléctricos en
              infraestructuras ferroviarias.
            </p>
          </div>
          <div class="col-12 col-lg-6 p-0"></div>
        </div>
        <div class="row bg-dark-50-entia mb-5" id="electrica">
          <div class="col-12 col-lg-6 p-0"></div>
          <div class="col-12 col-lg-6 p-5">
            <h1>Líneas de transmisión <br />y distribución eléctrica</h1>
            <p>
              <span
                >La transmisión eléctrica desempeña un rol clave en las
                necesidades actuales y futuras</span
              >
              de las infraestructuras eléctricas requeridas por el crecimiento
              de la demanda energética de nuestro país. Con más de 50 proyectos
              de líneas de transmisión y subestaciones desarrollados a lo largo
              del país, que representan más de
              <span>5,000 kilometros de líneas</span>, los profesionales en
              <span class="color-primary-entia fw-bold">ENTIA</span> cuentan con
              la experiencia y capacidad necesaria para una ejecución exitosa
              atendiendo a los requisitos más exigentes por parte de nuestros
              clientes.
            </p>
          </div>
        </div>
        <div class="row bg-dark-50-entia mb-5" id="carreteras">
          <div class="col-12 col-lg-6 p-5">
            <h1>
              Carreteras y<br />
              edificación
            </h1>
            <p>
              Los sistemas carreteras son críticos para lograr
              <span>conexiones seguras y eficientes</span> entre las comunidades
              en México.
              <span
                >Hemos desarrollado y construido algunas de las más avanzadas
                autopistas del país,</span
              >
              así como carreteras de la red estatal, con inversiones por un
              importe superior a los $600 millones de dólares. Nuestros
              profesionales han participado asimismo en la ejecución de
              <span
                >importantes proyectos de carreteras a nivel
                internacional.</span
              >
              Asimismo, contamos con vasta experiencia en edificaciones de
              equipamientos públicos tales como hospitales y centros locales
              asistenciales.
            </p>
          </div>
          <div class="col-12 col-lg-6 p-0">
            <!-- <img
              src="../assets/images/services/carreteras-y-edificacion-detalles.webp"
              alt="carreteras-y-edificacion"
              class="img-fluid"
            /> -->
          </div>
        </div>
        <div class="row bg-dark-50-entia" id="generacion-energia">
          <div class="col-12 col-lg-6 p-0"></div>
          <div class="col-12 col-lg-6 p-5">
            <h1>Generación de energía y medio ambiente</h1>
            <p>
              La generación de energía y el respeto por la conservación del
              medio ambiente constituyen dos aspectos esenciales para el
              crecimiento y desarrollo sostenible de un país. Por eso, para
              <span>ENTIA</span> representa un desafío constante el poner en
              valor nuestra experiencia acumulada en
              <span
                >proyectos de generación tanto en ciclo combinado y
                termoeléctrica</span
              >, así como en proyectos para la reducción de emisiones
              contaminantes y también en proyectos de energías renovables con
              plantas fotovoltaicas y parques eólicos.
            </p>
          </div>
        </div>
      </div>
    </section>
    <Footer msg="Tenemos la infraestructura para tu proyecto." />
  </div>
</template>

<script>
import Footer from "@/es/components/common/Footer";
import ArrowService from "@/es/components/common/ArrowService";

export default {
  name: "Services",
  components: {
    Footer,
    ArrowService,
  },
  created () {
    setTimeout(() => {
      if (this.$route.hash !== '') {
        const hash = window.location.hash.split("#")[1];
        this.scrollBehavior(hash);
      }
    }, 600);
  },
  methods: {
    scrollBehavior(id) {
      let element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  },
};
</script>

<style scoped>
.cursor {
  cursor: pointer;
}
</style>
